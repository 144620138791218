import React, { useState } from 'react'
import {
    Breadcrumbs,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Link as MLink,
    Typography,
    Button,
    Snackbar,
    Toolbar,
    IconButton,
    AppBar,
    Dialog,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { apiGet, apiGetFile, apiPost } from '../../functionsAPI'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditableTextArea from '../../components/EditableTextArea'
// import ImageEditor from '../../components/ImageEditor';
import ImageZoning from '../../components/ImageZoning'
import axios from 'axios'
import { URL } from '../../config'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

const ArticleScan = () => {
    const conf = localStorage.getItem('confThreshold'), token = localStorage.getItem('aoeToken')
    const classes = useStyles();
    const [openContent, setOpenContent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const progress = loading ? (<LinearProgress variant='determinate' value={percentage} />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [fileName, setFileName] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleAlert = (type, message) => {
        switch (type) {
            case 0:
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 1:
                setAlertType('success')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 2:
                setAlertType('info')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 3:
                setAlertType('warning')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            default:
                setAlertType('warning')
                setAlertMessage('Unknown Message')
                setShowAlert(true)
                break;
        }
    }
    const [converted, setConverted] = useState(false)
    const [files, setFiles] = useState([])
    const [content, setContent] = useState([])
    const [zones, setZones] = useState([])
    const [articleId, setArticleId] = useState(null)
    const [multipleFiles, setMulipleFiles] = useState(false)
    const [count, setCount] = useState(0)
    const [previewImage, setPreviewImage] = useState(null)
    const [showVerification, setShowVerification] = useState(false)

    const handleFilesChange = (files) => {
        setFiles(files)
        if (2 > files.length > 0 && files.length !== 0) {
            setMulipleFiles(false)
            setFileName(files[0].name)
        } else {
            setMulipleFiles(true)
            setFileName(null)
        }
        setArticleId(null)
        setConverted(false)
        setContent([])
        setZones([])
        setPreviewImage(null)
    }

    const submit = () => {
        setLoading(true)
        if (files.length === 0) {
            const message = 'Select at least 1 file.'
            handleAlert(0, message)
            setLoading(false)
            setPercentage(0)
        } else {

            if (files.length > 1) {
                setMulipleFiles(true)
            }
            var formData = new FormData();

            files.forEach(file => {
                formData.append('files', file);
            })

            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    token
                },
                onUploadProgress: progressEvent => {
                    const value = progressEvent.loaded
                    const total = progressEvent.total
                    const percentage = (value * 100) / total
                    setPercentage(percentage)
                }
            }

            const url = URL + '/batch'
            axios.post(url, formData, headerConfig)
                .then(response => {
                    setConverted(true)
                    setPercentage(0)
                    setLoading(false)
                }).catch(err => {
                    console.log(err);
                    handleAlert(0, err)
                    setPercentage(0)
                    setLoading(false)
                });
        }
    }

    // const setEditedImage = (file) => {
    //     setLoading(true)
    //     setFiles(file)
    //     handleCloseContent()
    //     var formData = new FormData()
    //     formData.append('article', file, fileName)
    //     const url = '/articles'
    //     apiPost(url, formData)
    //         .then(response => {
    //             setContent(response.data.words)
    //             setPreviewImage(response.data.path)
    //             setArticleId(response.data.id)
    //             setConverted(true)
    //             setLoading(false)
    //         }).catch(err => {
    //             handleAlert(0, err)
    //             setLoading(false)
    //         });
    // }

    const handleImageZoning = (file, zones) => {
        setLoading(true)
        handleCloseContent()
        const _zones = zones.length > 0 ? zones : null
        file && setFiles([file])
        const image = file ? file : files[0]
        var formData = new FormData()
        formData.append('article', image, fileName)
        formData.append('zones_list', JSON.stringify(_zones))
        const url = '/articles/v2'
        apiPost(url, formData)
            .then(response => {
                setContent(response.data.words)
                setZones(response.data.zones)
                getImage(response.data.path)
                setArticleId(response.data.id)
                setConverted(true)
                setShowVerification(true)
                setLoading(false)
            }).catch(err => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            });
    }

    const getImage = (path) => {
        apiGetFile('/files?f=' + path)
            .then((res) => {
                const url = new Blob([res.data], { type: res.data.type });
                setPreviewImage(url)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const getContent = () => {
        setLoading(true)
        apiGet(`/articles/${articleId}/words`)
            .then((res) => {
                setContent(res.data.words)
                setZones(res.data.zones)
                const objectsData = Object.values(res.data.words)
                const sortedWords = sortObjectsOrder(objectsData);
                const groupedWords = groupByBlocks(sortedWords);
                setData(groupedWords, res.data.zones)
                setLoading(false)
            })
            .catch((err) => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            });
    }

    function sortObjectsOrder(objectsData) {
        if (!Array.isArray(objectsData)) {
            return [];
        }

        return objectsData.sort((a, b) => {
            if (a.block_num !== b.block_num) {
                return a.block_num - b.block_num;
            }

            if (a.par_num !== b.par_num) {
                return a.par_num - b.par_num;
            }

            if (a.line_num !== b.line_num) {
                return a.line_num - b.line_num;
            }

            return a.word_num - b.word_num;
        });
    }


    function groupByBlocks(sortedWords) {
        return sortedWords.reduce((groups, word) => {
            const { block_num, par_num } = word;

            if (!groups[block_num]) {
                groups[block_num] = {};
            }

            if (!groups[block_num][par_num]) {
                groups[block_num][par_num] = [];
            }

            groups[block_num][par_num].push(word);
            return groups;
        }, {});
    }

    const setData = (groupedWords, zones) => {
        var data = '';
        if (zones.length > 0) {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((zone) => {
                const zoneArray = Object.values(zone)

                zoneArray.forEach((parNum) => {

                    const parNumArray = Object.values(parNum)
                    parNumArray.forEach((para) => {

                        const paraArray = Object.values(para)
                        // rendering of paragraph
                        paraArray.forEach((word) => {

                            data += word.corrected_text ?? word.generated_text
                            data += ' '

                        })
                        data += '\n\n'

                    })
                });

            })
            copyToClipboard(data);
        } else {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((parNum) => {

                const parNumArray = Object.values(parNum)
                parNumArray.forEach((para) => {

                    const paraArray = Object.values(para)
                    // rendering of paragraph
                    paraArray.forEach((word) => {

                        data += word.corrected_text ?? word.generated_text
                        data += ' '

                    })
                    data += '\n\n'

                })

            });
            copyToClipboard(data);
        }
    };

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data)
        const message = 'Copied to clipboard.'
        handleAlert(1, message)
    }

    const reset = () => {
        setConverted(false)
        setFiles([])
        setContent([])
        setZones([])
        setArticleId(null)
        setCount(count + 1)
        setMulipleFiles(false)
    }

    const handleClickOpenContent = () => {
        setOpenContent(true);
    };

    const handleCloseContent = () => {
        setOpenContent(false);
        setContent([])
        setZones([])
        setPreviewImage(null)
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h1" color="initial"></Typography>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Article Scan</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progress}
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} lg={12}>
                            <DropzoneArea
                                key={count}
                                acceptedFiles={['image/*']}
                                filesLimit={200}
                                showPreviewsInDropzone={false}
                                showPreviews={true}
                                previewText='Selected:'
                                useChipsForPreview={true}
                                showAlerts={['error']}
                                maxFileSize={600000000}
                                alertSnackbarProps={
                                    {
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }
                                    }
                                }
                                dropzoneText={"Drag and drop an image here or click"}
                                onChange={handleFilesChange}
                            />
                        </Grid>
                        {progress}
                        <Grid item >
                            {converted &&
                                <>
                                    {!multipleFiles && showVerification && <Button disabled={loading} className='mx-2' variant="contained" size="large" onClick={getContent}>
                                        Copy Content
                                    </Button>}
                                    <Button disabled={loading} className='mx-2' variant="contained" size="large" onClick={reset}>
                                        Upload New Image
                                    </Button>
                                </>

                            }
                            {!converted && <>
                                {/* {
                                    // show edit image only if uploaded files are less then 1
                                    !multipleFiles &&
                                    <Button disabled={loading} className='mx-2  my-2 my-md-0' variant="contained" size="large" onClick={handleClickOpenContent}>
                                        Edit Image
                                    </Button>
                                } */}

                                {
                                    // show Edit Image only if uploaded files are less then 1
                                    !multipleFiles &&
                                    <Button disabled={loading} className='mx-2  my-2 my-md-0' variant="contained" size="large" onClick={handleClickOpenContent}>
                                        Edit Image
                                    </Button>
                                }

                                <Button disabled={loading} className='mx-2  my-2 my-md-0' variant="contained" size="large" onClick={submit}>
                                    Convert
                                </Button>

                            </>}
                        </Grid>
                        {converted &&
                            <>
                                {!showVerification && <Grid item xs={12}>
                                    <p className='h4 text-center'>Articles successfully scanned and added to scan list.</p>
                                </Grid>}
                                {showVerification &&
                                    <Grid item xs={12}>
                                        <EditableTextArea image={previewImage} data={content} zones={zones} conf={conf} articleId={articleId} setLoading={setLoading} handleAlert={handleAlert} />
                                    </Grid>}
                            </>}
                    </Grid>
                </CardContent>
                <Dialog fullScreen open={openContent} onClose={handleCloseContent} >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseContent} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Content
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* uncomment in case image whitespacew functionality required
                         <Grid item xs={12} lg={12}>
                            {files.length > 0 && <ImageEditor files={files} setEditedImage={setEditedImage} />}
                        </Grid> */}
                        <Grid item xs={12}>
                            {files.length > 0 && <ImageZoning files={files[0]} handleImageZoning={handleImageZoning} loading={loading} progress={progress} />}
                        </Grid>
                    </Grid>
                </Dialog>
            </Card>
        </div >
    )
}

export default ArticleScan
