import './App.css'
import './assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Layout from './components/Layout'
import Login from './pages/authentication/Login'
import { Snackbar } from '@material-ui/core'
import ForgotPassword from './pages/authentication/ForgotPassword'
import { SnackbarProvider } from 'notistack'
import Slide from '@material-ui/core/Slide'
import { Alert } from '@material-ui/lab'


function App() {
    const [darkState, setDarkState] = useState(false)
    const palletType = darkState ? 'dark' : 'light'

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const primaryDark = '#0C69A9'
    const primaryLight = '#0C69A9'
    const mainPrimaryColor = darkState ? primaryDark : primaryLight

    const secondaryDark = '#FF740A'
    const secondaryLight = '#FF740A'
    const mainSecondaryColor = darkState ? secondaryDark : secondaryLight

    const darkTheme = createTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            }
        }
    })

    useEffect(() => {
        const darkState = localStorage.getItem('darkState')
        if (darkState === 'dark') {
            setDarkState(true)
        } else {
            setDarkState(false)
        }
        // eslint-disable-next-line
    }, [])

    const handleThemeChange = () => {
        setDarkState(!darkState)
        const palletType = darkState ? 'dark' : 'light'
        localStorage.setItem('darkState', palletType)
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                maxSnack={3}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={showAlert}
                    autoHideDuration={5000}
                    onClose={handleAlertClose}
                >
                    <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <div className="noSelect">
                    <Router>
                        <Route path='/login' component={Login} />
                        <Switch>
                            <Route exact path='/' render={(props) => {
                                const token = localStorage.getItem('aoeToken')
                                if (token !== null) {
                                    props.history.push('/dashboard')
                                }
                                else {
                                    props.history.push('/login')
                                }
                            }} />
                            <Route path='/forgot' component={ForgotPassword} />
                            <Route
                                path='/dashboard'
                                render={(props) => (
                                    <Layout {...props} handleThemeChange={handleThemeChange} darkState={darkState} />
                                )}
                            />
                        </Switch>
                    </Router>
                </div>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
