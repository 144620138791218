import React, { useState } from 'react'
import {
    Menu,
    MenuItem
} from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import PersonIcon from '@material-ui/icons/Person'

import { useHistory } from 'react-router-dom'

const ProfileMenu = () => {
    const history = useHistory()
    const _user = JSON.parse(localStorage.getItem('aoeUser'))
    const user = (_user === null) ? {} : _user
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        localStorage.removeItem('aoeToken')
        localStorage.removeItem('aoeUser')
        history.push('/login')
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <AccountCircleIcon style={{ color: "white" }} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    history.push('/dashboard/profile')
                }}>
                    <PersonIcon />&nbsp;&nbsp;{user.first_name}
                </MenuItem>
                <MenuItem onClick={logout}><PowerSettingsNewIcon />&nbsp;&nbsp;Logout</MenuItem>
            </Menu>
        </div>
    )
}

export default ProfileMenu
