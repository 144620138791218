import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, Tooltip } from '@material-ui/core'
import UndoIcon from '@material-ui/icons/Undo'
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import debounce from 'lodash/debounce'

const padding = 0
const minSize = 20
const defaultBorderSize = 4

function convertStringsToNumbersInArray(array) {
    return array.map(obj => {
        const newObj = {}
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                newObj[key] = !isNaN(obj[key]) ? parseFloat(obj[key]) : obj[key]
            } else {
                newObj[key] = obj[key]
            }
        }
        return newObj
    })
}

const processZones = (iniZones) => {
    const numberedZones = convertStringsToNumbersInArray(iniZones)

    // Sort the numberedZones array based on the ocr sequence
    const sortKey = (obj) => obj.ocr_sequence
    numberedZones.sort((a, b) => sortKey(a) - sortKey(b))

    // addition of type property in each zone
    return numberedZones.map((obj) => {
        return { ...obj, type: 'Zoning' }
    })
}

const nearPoint = (x, y, x1, y1, name, sensitivity) => {
    return Math.abs(x - x1) < sensitivity && Math.abs(y - y1) < sensitivity ? name : null
}

const nearLine = (x, y, lineStart, lineEnd, crossAxes, mainAxis, name, sensitivity) => {
    if (mainAxis === 'x') {
        return Math.abs(y - crossAxes) < sensitivity && (lineStart < x && x < lineEnd) ? name : null
    } else if (mainAxis === 'y') {
        return Math.abs(x - crossAxes) < sensitivity && (lineStart < y && y < lineEnd) ? name : null
    }
}

const cursorForPosition = position => {
    switch (position) {
        case "order":
            return "text"
        case "tc":
        case "bc":
            return "ns-resize"
        case "lc":
        case "rc":
            return "ew-resize"
        case "tl":
        case "br":
        case "start":
        case "end":
            return "nwse-resize"
        case "tr":
        case "bl":
            return "nesw-resize"
        default:
            return "move"
    }
}

const resizedCoordinates = (clientX, clientY, position, coordinates) => {
    const { left, top, width, height } = coordinates
    const x2 = left + width
    const y2 = top + height
    // minimum size for proper resizing controlls, note: negative dimensions create error while api calling
    switch (position) {
        case "tc":
            if (clientY < y2 - minSize) {
                return { left, top: clientY, x2, y2 }
            } else {
                return { left, top: y2 - minSize, x2, y2 }
            }
        case "bc":
            if (clientY > top + minSize) {
                return { left, top, x2, y2: clientY }
            } else {
                return { left, top, x2, y2: top + minSize }
            }
        case "lc":
            if (clientX < x2 - minSize) {
                return { left: clientX, top, x2, y2 }
            } else {
                return { left: x2 - minSize, top, x2, y2 }
            }
        case "rc":
            if (clientX > left + minSize) {
                return { left, top, x2: clientX, y2 }
            } else {
                return { left, top, x2: left + minSize, y2 }
            }
        case "tl":
        case "start":
            if (clientX > x2 - minSize && clientY > y2 - minSize) {
                return { left: x2 - minSize, top: y2 - minSize, x2, y2 }
            } else if (clientX > x2 - minSize) {
                return { left: x2 - minSize, top: clientY, x2, y2 }
            } else if (clientY > y2 - minSize) {
                return { left: clientX, top: y2 - minSize, x2, y2 }
            } else {
                return { left: clientX, top: clientY, x2, y2 }
            }
        case "tr":
            if (clientX < left + minSize && clientY > y2 - minSize) {
                return { left, top: y2 - minSize, x2: left + minSize, y2 }
            } else if (clientX < left + minSize) {
                return { left, top: clientY, x2: left + minSize, y2 }
            } else if (clientY > y2 - minSize) {
                return { left, top: y2 - minSize, x2: clientX, y2 }
            } else {
                return { left, top: clientY, x2: clientX, y2 }
            }
        case "bl":
            if (clientX > x2 - minSize && clientY < top + minSize) {
                return { left: x2 - minSize, top, x2, y2: top + minSize }
            } else if (clientX > x2 - minSize) {
                return { left: x2 - minSize, top, x2, y2: clientY }
            } else if (clientY < top + minSize) {
                return { left: clientX, top, x2, y2: top + minSize }
            } else {
                return { left: clientX, top, x2, y2: clientY }
            }
        case "br":
        case "end":
            if (clientX < left + minSize && clientY < top + minSize) {
                return { left, top, x2: left + minSize, y2: top + minSize }
            } else if (clientX < left + minSize) {
                return { left, top, x2: left + minSize, y2: clientY }
            } else if (clientY < top + minSize) {
                return { left, top, x2: clientX, y2: top + minSize }
            } else {
                return { left, top, x2: clientX, y2: clientY }
            }
        default:
            return null //should not really get here...
    }
}

const usePressedKeys = () => {
    const [pressedKeys, setPressedKeys] = useState(new Set())

    useEffect(() => {
        const handleKeyDown = event => {
            setPressedKeys(prevKeys => new Set(prevKeys).add(event.key))
        }

        const handleKeyUp = event => {
            setPressedKeys(prevKeys => {
                const updatedKeys = new Set(prevKeys)
                updatedKeys.delete(event.key)
                return updatedKeys
            })
        }

        document.addEventListener("keydown", handleKeyDown)
        document.addEventListener("keyup", handleKeyUp)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
            document.removeEventListener("keyup", handleKeyUp)
        }
    }, [])
    return pressedKeys
}

const ImageZoning = ({ files, handleImageZoning, zones, progress, loading }) => {
    const [action, setAction] = useState("none")
    const fileType = useRef(files.type)
    const [selectedElement, setSelectedElement] = useState(null)
    const [scale, setScale] = useState(1)
    const [whitespaceToggle, setWhitespaceToggle] = useState(false)
    const [showHandles, setShowHandles] = useState(true)
    const [imageElement, setImageElement] = useState(null)
    const canvasRef = useRef(null)
    const contextRef = useRef(null)
    const [shapes, setShapes] = useState(zones ? processZones(zones) : [])
    const [disabled, setDisabled] = useState(shapes.length > 0 ? false : true)
    const textRef = useRef()
    const pressedKeys = usePressedKeys()
    const parentRef = useRef(null)
    const [borderSize, setBorderSize] = useState(defaultBorderSize)

    // size factor is for setting rectangle border and ocr sequence fonts relative sizes
    const relativeSize = useRef(1)

    const canvasOffSetX = useRef(null)
    const canvasOffSetY = useRef(null)
    const startX = useRef(null)
    const startY = useRef(null)

    const getMouseCoordinates = (event, offsetX, offsetY) => {
        const clientX = (event.clientX - offsetX - padding) / scale
        const clientY = (event.clientY - offsetY - padding) / scale
        return { clientX, clientY }
    }


    const positionWithinElement = (x, y, element) => {
        const x1 = element.left
        const x2 = element.left + element.width
        const y1 = element.top
        const y2 = element.top + element.height
        const width = element.width
        const height = element.height

        const sensitivityVariable = relativeSize.current * (borderSize / defaultBorderSize)

        const topLeft = nearLine(x, y, x1, x1 + width / 20, y1, 'x', "tl", 3 * sensitivityVariable) || nearLine(x, y, y1, y1 + height / 20, x1, 'y', "tl", 3 * sensitivityVariable)
        const topCenter = nearLine(x, y, x1 + width / 20, x2 - width / 20, y1, 'x', "tc", 3 * sensitivityVariable)
        const leftCenter = nearLine(x, y, y1 + height / 20, y2 - height / 20, x1, 'y', "lc", 3 * sensitivityVariable)
        const rightCenter = nearLine(x, y, y1 + height / 20, y2 - height / 20, x2, 'y', "rc", 3 * sensitivityVariable)
        const bottomCenter = nearLine(x, y, x1 + width / 20, x2 - width / 20, y2, 'x', "bc", 3 * sensitivityVariable)
        const topRight = nearLine(x, y, x2 - width / 20, x2, y1, 'x', "tr", 3 * sensitivityVariable) || nearLine(x, y, y1, y1 + height / 20, x2, 'y', "tr", 3 * sensitivityVariable)
        const bottomLeft = nearLine(x, y, x1, x1 + width / 20, y2, 'x', "bl", 3 * sensitivityVariable) || nearLine(x, y, y2 - height / 20, y2, x1, 'y', "bl", 3 * sensitivityVariable)
        const bottomRight = nearLine(x, y, x2 - width / 20, x2, y2, 'x', "br", 3 * sensitivityVariable) || nearLine(x, y, y2 - height / 20, y2, x2, 'y', "br", 3 * sensitivityVariable)
        const order = nearPoint(x, y, x2 - 12 * sensitivityVariable, y1 + 17 * sensitivityVariable, "order", 8 * sensitivityVariable)
        const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null
        return topLeft || topRight || bottomLeft || bottomRight || topCenter || leftCenter || rightCenter || bottomCenter || order || inside
    }

    const getElementAtPosition = (x, y, elements) => {
        return elements
            .map(element => ({ ...element, position: positionWithinElement(x, y, element) }))
            .find(element => element.position !== null)
    }

    const handleLoadFile = () => {
        const reader = new FileReader()
        reader.onload = async () => {
            const img = new Image()
            img.onload = () => {
                setImageElement(img)
                const parent = parentRef.current
                if (parent) {
                    const parentWidth = parent.clientWidth
                    const imageWidth = img.width
                    const dScale = parentWidth / imageWidth
                    dScale < 1 && setScale(Math.min(Math.max(Math.floor(dScale * 100 - 1) / 100, 0.1), 5))
                }
            }
            img.src = reader.result
        }
        reader.readAsDataURL(files)
    }

    useEffect(() => {
        handleLoadFile()
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        const canvasOffSet = canvas.getBoundingClientRect()
        canvasOffSetY.current = canvasOffSet.top
        canvasOffSetX.current = canvasOffSet.left
        contextRef.current = context

        if (imageElement) {
            const imgRelativeWidth = imageElement.width / 1000
            relativeSize.current = imgRelativeWidth

            canvas.width = imageElement.width * scale
            canvas.height = imageElement.height * scale
            context.clearRect(0, 0, canvas.width, canvas.height)
            context.scale(scale, scale)
            context.drawImage(imageElement, 0, 0)

            shapes.forEach((shape) => {
                switch (shape.type) {
                    case 'Zoning':
                        context.beginPath()
                        shape.is_heading ? context.strokeStyle = '#ff9b00' : context.strokeStyle = '#008000'
                        context.setLineDash([])
                        context.lineWidth = relativeSize.current * borderSize
                        context.strokeRect(shape.left, shape.top, shape.width, shape.height)
                        context.rect(shape.left, shape.top, shape.width, shape.height)
                        shape.is_heading ? context.fillStyle = '#ff9b0022' : context.fillStyle = '#D7EBD744'
                        context.fill()
                        context.font = `${24 * relativeSize.current * (borderSize / defaultBorderSize)}px Arial`
                        context.textAlign = "end"
                        context.fillStyle = '#004000'
                        context.fillText(shape.ocr_sequence, shape.left + shape.width - 5 * relativeSize.current * (borderSize / defaultBorderSize), shape.top + 25 * relativeSize.current * (borderSize / defaultBorderSize))
                        break

                    case 'Overlay':
                        context.beginPath()
                        context.fillStyle = '#fff'
                        context.setLineDash([10 * relativeSize.current, 10 * relativeSize.current])
                        context.rect(shape.left, shape.top, shape.width, shape.height)
                        context.fill()
                        context.strokeStyle = '#008000'
                        context.lineWidth = relativeSize.current * 1
                        context.strokeRect(shape.left, shape.top, shape.width, shape.height)
                        break

                    default:
                        break
                }
            })

        }

        // eslint-disable-next-line
    }, [shapes, selectedElement, scale, borderSize, imageElement])

    useEffect(() => {
        if (showHandles && selectedElement) {
            drawHandles(selectedElement)
        }
        // eslint-disable-next-line
    }, [selectedElement])

    useEffect(() => {
        if (pressedKeys.has("Backspace") || pressedKeys.has("Delete")) {
            if (selectedElement) {
                if (action !== "writing") {
                    deleteElement(selectedElement)
                }
            }
        }

        if (pressedKeys.has("o") || pressedKeys.has("O")) {
            setWhitespaceToggle(true)
        }

        if (pressedKeys.has("z") || pressedKeys.has("Z")) {
            setWhitespaceToggle(false)
        }

        if (pressedKeys.has("h") || pressedKeys.has("H")) {
            updateZoneHeading()
        }

        const zoomFunction = event => {
            if (pressedKeys.has("Meta") || pressedKeys.has("Control")) {
                event.preventDefault()
                onZoom(event.deltaY * - 0.001)
            }
        }

        document.addEventListener("wheel", zoomFunction, { passive: false })
        return () => {
            document.removeEventListener("wheel", zoomFunction)
        }

        // eslint-disable-next-line
    }, [pressedKeys])

    const handleMouseDown = ({ nativeEvent }) => {
        const canvas = canvasRef.current
        const canvasOffSet = canvas.getBoundingClientRect()
        canvasOffSetY.current = canvasOffSet.top
        canvasOffSetX.current = canvasOffSet.left
        const { clientX, clientY } = getMouseCoordinates(nativeEvent, canvasOffSet.left, canvasOffSet.top)
        startX.current = clientX - padding
        startY.current = clientY - padding

        // Handling element selction while while they are overlapped
        let element
        if (selectedElement) {
            element = getElementAtPosition(clientX, clientY, [selectedElement])
            if (!element) {
                element = getElementAtPosition(clientX, clientY, shapes)
            }
        } else {
            element = getElementAtPosition(clientX, clientY, shapes)
        }

        if (element) {
            nativeEvent.target.style.cursor = element ? cursorForPosition(element.position) : "default"
            const offsetX = clientX - element.left
            const offsetY = clientY - element.top
            setSelectedElement({ ...element, offsetX, offsetY })
            setShowHandles(true)

            if (element.position === "inside") {
                setAction("moving")
            } else if (element.position === "order") {
                setAction("writing")
            } else {
                setAction("resizing")
            }
        } else if (whitespaceToggle) {
            const id = shapes.length + 1
            setSelectedElement(null)
            setShapes(prevShapes => [...prevShapes, { left: startX.current, top: startY.current, width: 0, height: 0, ocr_sequence: '', type: 'Overlay', id: id, is_heading: false }])
            setAction('Overlay')
        } else {
            const id = shapes.length + 1
            setSelectedElement(null)
            setShapes(prevShapes => [...prevShapes, { left: startX.current, top: startY.current, width: 0, height: 0, ocr_sequence: '', type: 'Zoning', id: id, is_heading: false }])
            setAction("drawing")
        }
    }

    const handleMouseMove = ({ nativeEvent }) => {
        const canvas = canvasRef.current
        const canvasOffSet = canvas.getBoundingClientRect()
        canvasOffSetY.current = canvasOffSet.top
        canvasOffSetX.current = canvasOffSet.left
        nativeEvent.preventDefault()
        nativeEvent.stopPropagation()
        const shapesCopy = [...shapes]

        const { clientX, clientY } = getMouseCoordinates(nativeEvent, canvasOffSetX.current, canvasOffSetY.current)

        if (selectedElement) {
            const element = getElementAtPosition(clientX, clientY, [selectedElement])
            nativeEvent.target.style.cursor = element ? cursorForPosition(element.position) : "default"
        }

        if (action === 'Overlay') {
            const index = shapes.length - 1
            const { top, left } = shapes[index]
            const width = clientX - left, height = clientY - top
            const updatedShape = { ...shapes[index], width, height }

            shapesCopy[index] = updatedShape
            setShapes(shapesCopy)
            setShowHandles(false)
            return
        }

        if (action === "drawing") {
            const shapesCopy = [...shapes]
            const ocr_sq = shapesCopy.filter(shape => shape.type === 'Zoning').length
            const index = shapes.length - 1
            const { top, left } = shapes[index]
            const width = clientX - left, height = clientY - top
            const updatedShape = { ...shapes[index], ocr_sequence: ocr_sq, width, height }

            shapesCopy[index] = updatedShape
            setShapes(shapesCopy)

        } else if (action === "moving") {
            if (selectedElement) {
                const { id, offsetX, offsetY } = selectedElement
                const newLeft = clientX - offsetX
                const newTop = clientY - offsetY

                const index = shapesCopy.findIndex((shape) => shape.id === id)
                shapesCopy[index] = { ...selectedElement, left: newLeft, top: newTop }
                nativeEvent.target.style.cursor = shapesCopy[index] ? cursorForPosition(shapesCopy[index].position) : "default"
                setShapes(shapesCopy)
                setShowHandles(false)
            }
        } else if (action === "resizing") {
            if (selectedElement) {
                const { id, type, position, ...coordinates } = selectedElement
                const { left, top, x2, y2 } = resizedCoordinates(clientX, clientY, position, coordinates)
                const width = x2 - left
                const height = y2 - top

                const index = shapesCopy.findIndex((shape) => shape.id === id)
                shapesCopy[index] = { ...selectedElement, left, width, top, height }
                setShapes(shapesCopy)
                setShowHandles(false)
            }
        }
    }

    const handleMouseUp = () => {
        const shapesCopy = [...shapes]
        if (action === "drawing" || action === "Overlay") {

            const index = shapes.length - 1
            const newshape = shapes[index]

            // change coordinates values to positive, this is required when the rectangle is drawn from any corner other then top left
            if (newshape.height < 0) {
                newshape.top = newshape.top + newshape.height
                newshape.height = Math.abs(newshape.height)
            }

            if (newshape.width < 0) {
                newshape.left = newshape.left + newshape.width
                newshape.width = Math.abs(newshape.width)
            }

            // removing small width and height rectangles, to prevent accidental drawing
            if (newshape.height < minSize) {
                shapesCopy.pop()
            } else if (newshape.width < minSize) {
                shapesCopy.pop()
            } else {
                shapesCopy[index] = newshape
                setSelectedElement(newshape)
            }

            setShapes(shapesCopy)

            if (shapesCopy.length > 0) {
                setDisabled(false)
            }
        }

        if (action === "moving" || action === 'resizing') {
            const index = shapesCopy.findIndex((shape) => shape.id === selectedElement.id)
            setSelectedElement(shapesCopy[index])
        }

        if (action !== "writing") {
            setAction("none")
        }
        setShowHandles(true)
    }

    function drawRect(x, y, width, height, color) {
        const context = contextRef.current
        context.fillStyle = color
        context.beginPath()
        context.fillRect(x, y, width, height)
        context.fill()
    }

    function drawHandles(selectedElement) {
        const left = selectedElement.left
        const top = selectedElement.top
        const width = selectedElement.width
        const height = selectedElement.height
        const x2 = left + width
        const y2 = top + height
        const xc = left + width / 2
        const yc = top + height / 2
        const dimensionFraction = 15
        const color = selectedElement.is_heading ? "#ff9b00" : "#008000"

        // here corner's rectangle (top-right, top-left, bottom-left and bottom-right) dimension's are set fixed considering its effective area (works in corner only)

        var handlePreset

        if (selectedElement.type === 'Overlay') {
            handlePreset = borderSize * relativeSize.current
        } else {
            handlePreset = borderSize * 1.6 * relativeSize.current
        }

        const rectOffset = handlePreset / 2
        drawRect(left - rectOffset, top - rectOffset, width / 20, handlePreset, color)
        drawRect(left - rectOffset, top - rectOffset, handlePreset, height / 20, color)

        drawRect(x2 + rectOffset, top - rectOffset, - width / 20, handlePreset, color)
        drawRect(x2 + rectOffset, top - rectOffset, - handlePreset, height / 20, color)

        drawRect(left - rectOffset, y2 + rectOffset, width / 20, - handlePreset, color)
        drawRect(left - rectOffset, y2 + rectOffset, handlePreset, - height / 20, color)

        drawRect(x2 + rectOffset, y2 + rectOffset, - width / 20, - handlePreset, color)
        drawRect(x2 + rectOffset, y2 + rectOffset, - handlePreset, - height / 20, color)

        drawRect(xc - width / (dimensionFraction / 2), top - rectOffset, width / (dimensionFraction / 4), handlePreset, color)

        drawRect(xc - width / (dimensionFraction / 2), y2 - rectOffset, width / (dimensionFraction / 4), handlePreset, color)

        drawRect(left - rectOffset, yc - height / (dimensionFraction / 2), handlePreset, height / (dimensionFraction / 4), color)
        drawRect(x2 - rectOffset, yc - height / (dimensionFraction / 2), handlePreset, height / (dimensionFraction / 4), color)
    }

    const handleOrderChange = (newOrder) => {
        var targetOrder = parseInt(newOrder)

        // only change order if provided value is greater then 0
        if (targetOrder > 0) {
            const shapesCopy = [...shapes]
            const shapesCopy2 = [...shapes]

            const currentOrder = selectedElement.ocr_sequence
            const index = shapesCopy.findIndex((shape) => shape.ocr_sequence === currentOrder)
            const max_order = shapesCopy2.filter(shape => shape.type === 'Zoning').length

            // proceed only if selected elements index found
            // index can be 0, hence second condition provided
            if (index || index === 0) {

                // set target order equal to last element if the value is high
                if (targetOrder > max_order) {
                    targetOrder = max_order
                }

                // get position of zoning element which has one sequence less then target element
                // Always slice after finding the prevZoneIndex, otherwise it will create bug
                const prevZoneIndex = shapesCopy.findIndex((shape) => shape.ocr_sequence === targetOrder - 1)

                // remove selected element from current order position
                shapesCopy.splice(index, 1)

                // add selected element to new position
                shapesCopy.splice(prevZoneIndex + 1, 0, selectedElement)

                // set new ordering sequence
                let count = 1
                shapesCopy.forEach((shape) => {
                    if (shape.type === "Zoning") {
                        shape.ocr_sequence = count
                        count += 1
                    }
                })

                setShapes(shapesCopy)

            } else {
                console.log('something went wrong')
            }
        }
    }

    const handleBlur = event => {
        setAction("none")
        setSelectedElement(null)
        handleOrderChange(event.target.value)
    }

    const checkEnter = event => {
        if (event.key === "Enter") {
            handleOrderChange(event.target.value)
            setAction("none")
        }
    }

    const deleteElement = () => {
        if (selectedElement) {
            const canvas = canvasRef.current
            const shapesCopy = [...shapes]
            const index = shapesCopy.findIndex((shape) => shape.id === selectedElement.id)
            shapesCopy.splice(index, 1)
            let count = 1
            shapesCopy.forEach((shape, index) => {
                if (shape.type === 'Zoning') {
                    shape.ocr_sequence = count
                    count += 1
                }
            })
            setShapes(shapesCopy)
            setSelectedElement(null)
            if (shapesCopy.length === 0) {
                setDisabled(true)
            }
            canvas.style.cursor = "default"
        }
    }

    const updateZoneHeading = () => {
        const shapesCopy = [...shapes]
        if (selectedElement) {
            if (selectedElement.type === "Zoning") {
                const { id, is_heading } = selectedElement
                const newHeading = !is_heading
                const index = shapesCopy.findIndex((shape) => shape.id === id)
                shapesCopy[index] = { ...selectedElement, is_heading: newHeading }
                setShapes(shapesCopy)
                setSelectedElement({ ...selectedElement, is_heading: newHeading })
            }
        }
    }

    const undoShapes = () => {
        const shapesCopy = [...shapes]
        shapesCopy.pop()
        setShapes(shapesCopy)
        setSelectedElement(null)
        if (shapesCopy.length === 0) {
            setDisabled(true)
        }
    }

    const resetShapes = () => {
        const canvas = canvasRef.current
        setShapes([])
        setSelectedElement(null)
        setDisabled(true)
        canvas.style.cursor = "default"
    }

    const handleOCR = () => {
        const canvas = canvasRef.current
        const img = imageElement
        const context = canvas.getContext('2d')
        const shapesCopy = [...shapes]
        canvas.width = img.width
        canvas.height = img.height
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.drawImage(img, 0, 0)

        shapes.forEach((shape) => {
            switch (shape.type) {
                case 'Overlay':
                    context.beginPath()
                    context.fillStyle = '#fff'
                    context.rect(shape.left, shape.top, shape.width, shape.height)
                    context.fill()
                    break
                default:
                    break
            }
        })

        const zones = shapes.filter(shape => shape.type === 'Zoning')

        const overlayCount = shapesCopy.filter(shape => shape.type === 'Overlay').length

        if (overlayCount > 0) {
            canvas.toBlob(blob => {
                const file = new File([blob], 'testing', { type: blob.type })
                handleImageZoning(file, zones)
            }, fileType.current)
        } else {
            handleImageZoning(null, zones)
        }
    }

    // eslint-disable-next-line
    const onZoom = useCallback(
        debounce((zoom) => {
            if (zoom === 0.25) {

                setScale((prevZoom) => {
                    const zoom_ = zoomIn(prevZoom)
                    return Math.min(Math.max(zoom_, 0.1), 5)
                })

            } else if (zoom === -0.25) {

                setScale((prevZoom) => {
                    const zoom_ = zoomOut(prevZoom)
                    return Math.min(Math.max(zoom_, 0.1), 5)
                })

            } else {

                setScale((prevZoom) => Math.min(Math.max(prevZoom + zoom, 0.1), 5))

            }
        }, 50),
        []
    )

    const zoomIn = (prevZoom) => {
        switch (true) {
            case (prevZoom < 0.25):
                return 0.25
            case (prevZoom < 0.5):
                return 0.5
            case (prevZoom < 0.75):
                return 0.75
            case (prevZoom <= 1):
                return 1
            case (prevZoom > 1):
                return 1
            default:
                return 1
        }
    }

    const zoomOut = (prevZoom) => {
        switch (true) {
            case (prevZoom < 0.26):
                return 0.1
            case (prevZoom < 0.51):
                return 0.25
            case (prevZoom < 0.76):
                return 0.5
            case (prevZoom <= 1):
                return 0.75
            case (prevZoom > 1):
                return 1
            default:
                return 1
        }
    }

    return (
        <Container fluid>
            <Row ref={parentRef} className='flex-column align-items-center justify-content-center gy-3'>
                {progress}
                <Col className='text-center mt-4'>
                    <span className='d-inline-block mx-1 py-2 bg-light bg-gradient rounded'>
                        <Tooltip title={whitespaceToggle ? 'Overlay (O)' : 'Zoning (Z)'}>
                            <Button size='small' className='mx-3' variant="contained" disabled={loading} onClick={() => setWhitespaceToggle(!whitespaceToggle)}>
                                {whitespaceToggle ? 'O' : 'Z'}
                            </Button>
                        </Tooltip>
                    </span>
                    <span className='d-inline-block mx-1 py-2 bg-light bg-gradient rounded'>
                        <Tooltip title='Heading (H)'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={updateZoneHeading}>
                                H
                            </Button>
                        </Tooltip>
                        <Tooltip title='Border width Decrease'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={() => setBorderSize(prevSize => Math.min(Math.max(prevSize - 1, 1), 8))}>
                                -
                            </Button>
                        </Tooltip>
                        <Tooltip title='Border width'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={() => setBorderSize(defaultBorderSize)}>
                                {new Intl.NumberFormat("en-GB").format(borderSize / defaultBorderSize)}
                            </Button>
                        </Tooltip>
                        <Tooltip title='Border width Increase'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={() => setBorderSize(prevSize => Math.min(Math.max(prevSize + 1, 1), 8))}>
                                +
                            </Button>
                        </Tooltip>
                        <Tooltip title='Remove Last Zone'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={undoShapes}>
                                <UndoIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title='Remove All Zones'>
                            <Button size='small' className='mx-3' variant="contained" disabled={disabled || loading} onClick={resetShapes}>
                                <NotInterestedIcon />
                            </Button>
                        </Tooltip>
                    </span>
                    <span className='d-inline-block mx-1 py-2 bg-light bg-gradient rounded'>
                        <Tooltip title='Process Image'>
                            <Button size='small' className='mx-3' color='primary' variant="contained" disabled={loading} onClick={handleOCR}>
                                OCR
                            </Button>
                        </Tooltip>
                    </span>
                </Col>
                <Col className='scrolable-editor text-center my-4'>
                    {action === "writing" && shapes.length > 0 && selectedElement && (
                        <input
                            type='number'
                            ref={textRef}
                            onBlur={handleBlur}
                            onKeyDown={checkEnter}
                            style={{
                                maxWidth: `${60 * relativeSize.current * scale}px`,
                                position: "fixed",
                                top: selectedElement.top * scale + canvasOffSetY.current + (borderSize * relativeSize.current * scale),
                                left: (selectedElement.left + selectedElement.width) * scale + canvasOffSetX.current - (24 * relativeSize.current * scale),
                                font: `${24 * relativeSize.current * scale * (borderSize / defaultBorderSize)}px sans-serif`,
                                margin: 0,
                                padding: 0,
                                border: 0,
                                outline: 0,
                                resize: "auto",
                                overflow: "hidden",
                                whiteSpace: "pre",
                                background: "transparent",
                                zIndex: 2,
                            }}
                        />
                    )}
                    <div style={{ position: 'fixed', zIndex: 2, bottom: 0, padding: 10 }}>
                        <Button disabled={loading} className='p-0 mx-1' variant='contained' onClick={() => onZoom(-0.25)}>-</Button>
                        <Button disabled={loading} className='p-0 mx-1' variant='contained' onClick={() => setScale(1)}> {new Intl.NumberFormat("en-GB", { style: 'percent' }).format(scale)} </Button>
                        <Button disabled={loading} className='p-0 mx-1' variant='contained' onClick={() => onZoom(0.25)}>+</Button>
                    </div>
                    <canvas
                        style={{ padding: `${padding}px`, border: '1px solid #030303' }}
                        ref={canvasRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                    />
                    {progress}
                </Col>
            </Row>
        </Container >
    )
}

export default ImageZoning
