import React, { useState, useEffect } from 'react'
import { utils, writeFile } from 'xlsx';
import EditableTextArea from '../../components/EditableTextArea';
import {
    AppBar,
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Slide,
    Snackbar,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { apiDelete, apiGet, apiGetFile, apiPost } from '../../functionsAPI'
import { checkOperations, dateFormate, dateToString, yearFormate } from '../../functions'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete'
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment'
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import Table from '../../components/Table'
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers'
import ImageZoning from '../../components/ImageZoning';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    filter: {
        padding: theme.spacing(4),
        width: '450px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListArticles = (props) => {
    const conf = localStorage.getItem('confThreshold')
    const user_ = JSON.parse(localStorage.getItem('aoeUser'))
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleAlert = (type, message) => {
        if (typeof message === "string") {
            switch (type) {
                case 0:
                    setAlertType('error')
                    setAlertMessage(message)
                    setShowAlert(true)
                    break;
                case 1:
                    setAlertType('success')
                    setAlertMessage(message)
                    setShowAlert(true)
                    break;
                case 2:
                    setAlertType('info')
                    setAlertMessage(message)
                    setShowAlert(true)
                    break;
                case 3:
                    setAlertType('warning')
                    setAlertMessage(message)
                    setShowAlert(true)
                    break;
                default:
                    setAlertType('warning')
                    setAlertMessage('Unknown Message')
                    setShowAlert(true)
                    break;
            }
        } else {
            console.log(type, message);
        }
    }
    const classes = useStyles();
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showClear, setShowClear] = useState(false)
    const [glossaryToggle, setGlossaryToggle] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [showClearDates, setShowClearDates] = useState(false)
    const [singleDate, setSingleDate] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [glossaryYear, setGlossaryYear] = useState(moment())

    const [dialogue1Toggle, setDialogue1Toggle] = useState(false)
    const [content, setContent] = useState(null)
    const [zones, setZones] = useState([])
    const [openContent, setOpenContent] = useState(false)
    const [openReOCR, setOpenReOCR] = useState(false)
    const [articleId, setArticleId] = useState(null)

    const [users, setUsers] = useState([])
    const [user, setUser] = useState(user_)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [artFromDate, setArtFromDate] = useState(null)
    const [artToDate, setArtToDate] = useState(null)
    const [artSingleDate, setArtSingleDate] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Article Name",
            options: {
                sort: true,
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (data) => {
        setLoading(true)
        const fromDate_ = data._fromDate ? dateToString(data._fromDate) : 'all'
        const toDate_ = data._toDate ? dateToString(data._toDate) : 'all'
        const date_ = data._singleDate ? dateToString(data._singleDate) : 'all'
        const artFromDate_ = data._artFromDate ? dateToString(data._artFromDate) : 'all'
        const artToDate_ = data._artToDate ? dateToString(data._artToDate) : 'all'
        const artSingleDate_ = data._artSingleDate ? dateToString(data._artSingleDate) : 'all'
        const user_id_ = data._user ? data._user.id : 'all'

        if (!((fromDate && toDate) || (!fromDate && !toDate))) {
            handleAlert(0, "Error: Either both Upload from Date and Upload to Date must be provided, or none of them.");
            setLoading(false)
            return;
        }

        if (!((artFromDate && artToDate) || (!artFromDate && !artToDate))) {
            handleAlert(0, "Error: Either both Article from Date and Article to Date must be provided, or none of them.");
            setLoading(false)
            return;
        }
        const params = {
            sort_by: data._sortBy,
            order: data._order,
            start: data._start,
            limit: data._limit,
            search: data._search,
            user_id: user_id_,
            date: date_,
            from_date: fromDate_,
            to_date: toDate_,
            article_from_date: artFromDate_,
            article_to_date: artToDate_,
            article_date: artSingleDate_,
            status: 'Success'
        }
        const url = '/articles'
        apiGet(url, params)
            .then(response => {
                response.data.list.map(item => {
                    let action = (
                        <div>
                            {checkOperations('View Article') && <Tooltip title="Article Verification">
                                <IconButton onClick={() => {
                                    getContent(item.id, true)
                                }}>
                                    <SpellcheckIcon />
                                </IconButton>
                            </Tooltip>}
                            {checkOperations('View Article') && <Tooltip title="Reprocess image">
                                <IconButton onClick={() => {
                                    handleClickReOCR(item.id)
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>}
                            {checkOperations('View Article') && <Tooltip title="Copy To Clipboard">
                                <IconButton onClick={() => {
                                    getContent(item.id, false)
                                }}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>}
                            {checkOperations('View Article') && <Tooltip title="Download txt file">
                                <IconButton onClick={() => {
                                    getWords(item.id)
                                }}>
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>}
                            {checkOperations('View Article') &&
                                <Tooltip title="Download Glossary">
                                    <IconButton>
                                        <DescriptionIcon onClick={() => handleExcel(item.id)} ></DescriptionIcon>
                                    </IconButton>
                                </Tooltip>}
                            {checkOperations('View Article') &&
                                <Tooltip title="Download Image">
                                    <IconButton >
                                        <ImageIcon onClick={() => downloadImage(item.path, item.name)} />
                                    </IconButton>
                                </Tooltip>}
                            {checkOperations('Delete Article') && <Tooltip title="Delete Article">
                                <IconButton onClick={() => handleDialogue1Open(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>}
                        </div>
                    )
                    item.action = action
                    return true
                })
                setCount(response.data.count)
                setTableData(response.data.list)
                setLoading(false)
            }).catch(err => {
                console.log(err);
                handleAlert(0, err)
                setLoading(false);
            });
    }

    const handleExcel = (id) => {
        setArticleId(id)
        setLoading(true)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setContent(res.data.words)
                setZones(res.data.zones)
                const objectsData = Object.values(res.data.words)
                const sortedWords = sortObjectsOrder(objectsData);
                const groupedWords = groupByBlocks(sortedWords);
                setExcelData(groupedWords, id, res.data.name, res.data.zones)
                setLoading(false)
            })
            .catch((err) => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        const data = {
            _start: 0,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            _user: user
        }
        getList(data)
        getUsers()
        getConfig()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (fromDate || toDate || singleDate || artFromDate || artToDate || artSingleDate) {
            setShowClear(true)
        } else {
            setShowClear(false)
        }
        // eslint-disable-next-line
    }, [fromDate, toDate, singleDate, artFromDate, artToDate, artSingleDate])

    const getContent = (id, value) => {
        setLoading(true)
        setArticleId(id)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setContent(res.data.words)
                getImage(res.data.path)
                setZones(res.data.zones)
                const objectsData = Object.values(res.data.words)
                const sortedWords = sortObjectsOrder(objectsData);
                const groupedWords = groupByBlocks(sortedWords);
                value && handleClickOpenContent()
                !value && setData(groupedWords, res.data.zones)
                setLoading(false)
            })
            .catch((err) => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            });
    }

    const getImage = (path) => {
        apiGetFile('/files?f=' + path)
            .then((res) => {
                const url = new Blob([res.data], { type: res.data.type });
                setPreviewImage(url)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const getWords = (id) => {
        setLoading(true)
        setArticleId(id)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setContent(res.data.words);
                setZones(res.data.zones);
                const objectsData = Object.values(res.data.words);
                const sortedWords = sortObjectsOrder(objectsData);
                const groupedWords = groupByBlocks(sortedWords);
                setTxt(groupedWords, res.data.name, res.data.zones);
                setLoading(false);
            })
            .catch((err) => {
                handleAlert(0, err);
                console.log(err);
                setLoading(false);
            });
    }


    const getUsers = () => {
        setLoading(true)
        apiGet(`/users/all`)
            .then((res) => {
                setUsers(res.data)
                setLoading(false)
            })
            .catch((err) => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            });
    }

    function sortObjectsOrder(paragraphWords) {
        if (!Array.isArray(paragraphWords)) {
            return [];
        }

        return paragraphWords.sort((a, b) => {
            if (a.zone && b.zone) {
                if (a.zone.ocr_sequence !== b.zone.ocr_sequence) {
                    return a.zone.ocr_sequence - b.zone.ocr_sequence;
                }
            }

            if (a.block_num !== b.block_num) {
                return a.block_num - b.block_num;
            }

            if (a.par_num !== b.par_num) {
                return a.par_num - b.par_num;
            }

            if (a.line_num !== b.line_num) {
                return a.line_num - b.line_num;
            }

            return a.word_num - b.word_num;
        });
    }


    function groupByBlocks(sortedWords) {
        return sortedWords.reduce((groups, word) => {
            const { zone, block_num, par_num } = word;
            if (!zone) {
                if (!groups[block_num]) {
                    groups[block_num] = {};
                }

                if (!groups[block_num][par_num]) {
                    groups[block_num][par_num] = [];
                }

                groups[block_num][par_num].push(word);

            } else {
                if (!groups[zone.ocr_sequence]) {
                    groups[zone.ocr_sequence] = {};
                }

                if (!groups[zone.ocr_sequence][block_num]) {
                    groups[zone.ocr_sequence][block_num] = [];
                }

                if (!groups[zone.ocr_sequence][block_num][par_num]) {
                    groups[zone.ocr_sequence][block_num][par_num] = [];
                }

                groups[zone.ocr_sequence][block_num][par_num].push(word);
            }
            return groups;
        }, {});
    }

    const setData = (groupedWords, zones) => {
        var data = '';
        if (zones.length > 0) {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((zone) => {
                const zoneArray = Object.values(zone)

                zoneArray.forEach((parNum) => {

                    const parNumArray = Object.values(parNum)
                    parNumArray.forEach((para) => {

                        const paraArray = Object.values(para)
                        // rendering of paragraph
                        paraArray.forEach((word) => {

                            data += word.corrected_text ?? word.generated_text
                            data += ' '

                        })
                        data += '\n\n'

                    })

                });

            })
            copyToClipboard(data);
        } else {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((parNum) => {

                const parNumArray = Object.values(parNum)
                parNumArray.forEach((para) => {

                    const paraArray = Object.values(para)
                    // rendering of paragraph
                    paraArray.forEach((word) => {

                        data += word.corrected_text ?? word.generated_text
                        data += ' '

                    })
                    data += '\n\n'

                })

            });
            copyToClipboard(data);
        }
    };

    const setTxt = (groupedWords, articleName, zones) => {
        var data = '';

        if (zones.length > 0) {
            const groupedWordsArray = Object.values(groupedWords)

            groupedWordsArray.forEach((zone) => {
                const zoneArray = Object.values(zone)

                zoneArray.forEach((parNum) => {

                    const parNumArray = Object.values(parNum)
                    parNumArray.forEach((para) => {

                        const paraArray = Object.values(para)
                        // rendering of paragraph
                        paraArray.forEach((word) => {

                            data += word.corrected_text ?? word.generated_text
                            data += ' '

                        })
                        data += '\n\n'

                    })

                });

            })

            downloadTxt(data, articleName);

        } else {
            const groupedWordsArray = Object.values(groupedWords)

            groupedWordsArray.forEach((parNum) => {

                const parNumArray = Object.values(parNum)
                parNumArray.forEach((para) => {

                    const paraArray = Object.values(para)
                    // rendering of paragraph
                    paraArray.forEach((word) => {

                        data += word.corrected_text ?? word.generated_text
                        data += ' '

                    })
                    data += '\n\n'

                })

            });

            downloadTxt(data, articleName);
        }
    };

    const downloadTxt = (data, articleName) => {
        try {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${articleName}.txt`)
            document.body.appendChild(link);
            link.click();
            link.remove()
            handleAlert(1, 'TXT file Downloaded')
        } catch (error) {
            handleAlert(0, 'Error creating download link:');
        }
    }

    const changePage = (page) => {
        const start_ = limit * (page)
        const data = {
            _start: start_,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            _fromDate: fromDate,
            _toDate: toDate,
            _user: user,
            _singleDate: singleDate,
            _artSingleDate: artSingleDate,
            _artFromDate: artFromDate,
            _artToDate: artToDate,
        }
        getList(data)
        setStart(start_)
    }

    const sort = (sortOrder) => {
        const sortBy_ = sortOrder.name
        const order_ = sortOrder.direction
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy_,
            _order: order_,
            _search: search,
            _fromDate: fromDate,
            _toDate: toDate,
            _user: user,
            _singleDate: singleDate,
            _artSingleDate: artSingleDate,
            _artFromDate: artFromDate,
            _artToDate: artToDate,
        }
        getList(data)
        setSortBy(sortBy_)
        setOrder(order_)
    }

    const changeRowsPerPage = (limit_) => {
        const data = {
            _start: start,
            _limit: limit_,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            _fromDate: fromDate,
            _toDate: toDate,
            _user: user,
            _singleDate: singleDate,
            _artSingleDate: artSingleDate,
            _artFromDate: artFromDate,
            _artToDate: artToDate,
        }
        getList(data)
        setLimit(limit_)
    }

    const onSearch = (search_) => {
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search_,
            _fromDate: fromDate,
            _toDate: toDate,
            _user: user,
            _singleDate: singleDate,
            _artSingleDate: artSingleDate,
            _artFromDate: artFromDate,
            _artToDate: artToDate,
        }
        getList(data)
        setSearch(search_)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleClickOpenContent = () => {
        setOpenContent(true);
    };

    const handleCloseContent = () => {
        setOpenContent(false);
        setDialogue1Toggle(false);
        setPreviewImage(null);
        setContent(null);
        setZones([]);
    };

    const handleClickReOCR = (id) => {
        setLoading(true)
        setArticleId(id)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setZones(res.data.zones)
                setFileName(res.data.name)

                apiGetFile('/files?f=' + res.data.path)
                    .then((res) => {
                        const url = new Blob([res.data], { type: res.data.type });
                        setFile(url)
                        setOpenContent(false)
                        setLoading(false)
                        setOpenReOCR(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        setAlertType('error')
                        setAlertMessage(err)
                        setShowAlert(true)
                        setLoading(false)
                    });

            })
            .catch((err) => {
                console.log(err);
                handleAlert(0, err)
                setLoading(false)
            });
    };

    const handleCloseReOCR = () => {
        setOpenReOCR(false);
        setContent(null);
        setZones([]);
        setPreviewImage(null);
        setFile(null)
    };

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data);
        const message = 'Copied to clipboard.';
        handleAlert(1, message);
        // try {
        //     const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'Haribhakt_report.txt')
        //     document.body.appendChild(link);
        //     link.click();
        //     link.remove()
        // } catch (error) {
        //     handleAlert(0, 'Error creating download link:');
        // }

        // const doc = new jsPDF();
        // const topMargin = 10;
        // const bottomMargin = 10;
        // const leftMargin = 10;
        // const rightMargin = 10;
        // doc.setMargins(leftMargin, topMargin, rightMargin, bottomMargin);
        // doc.setFont("helvetica", "bold")
        // doc.setFontSize(10)
        // doc.text(`${data}`, 4, 4, { align: 'left' })
        // doc.save(`${'asdflkj'}.pdf`)
    }

    const downloadImage = (path, name) => {
        apiGetFile('/files?f=' + path)
            .then((res) => {
                const image_type = res.data.type.split("/")[1]
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = `${name}.${image_type}`;
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const deleteItem = (articleId) => {
        setLoading(true)
        apiDelete(`/articles/${articleId}`)
            .then(res => {
                const message = 'Article Deleted.'
                handleAlert(1, message)
                setDialogue1Toggle(false)
                setLoading(false)
                const data = {
                    _start: start,
                    _limit: limit,
                    _sortBy: sortBy,
                    _order: order,
                    _search: search,
                    _fromDate: fromDate,
                    _toDate: toDate,
                    _user: user,
                    _singleDate: singleDate,
                    _artSingleDate: artSingleDate,
                    _artFromDate: artFromDate,
                    _artToDate: artToDate,
                }
                getList(data)
            }).catch(error => {
                handleAlert(0, error)
                setLoading(false)
            })
    }

    const handleDialogue1Open = (articleId) => {
        setDialogue1Toggle(true)
        setArticleId(articleId)
    }

    const handleDialogue1Close = () => {
        setDialogue1Toggle(false)
        setArticleId(null)
    }

    const getConfig = () => {
        apiGet(`/settings/all`)
            .then((res) => {
                const value = Number(res.data[0].conf)
                localStorage.setItem('confThreshold', value)
            })
            .catch((err) => {
                handleAlert(0, 'error while fetching confidence threshold')
                console.log(err);
            });
    }

    const openGlossary = () => {
        setGlossaryToggle(true);
        // setGlossaryYear(moment())
    }

    const handleGlossaryClose = () => {
        setGlossaryToggle(false);
        setArticleId(null);
    }

    const downloadGlossary = () => {
        setLoading(true);
        const year = yearFormate(glossaryYear);
        const data = {
            from_date: `${year}-01-01`,
            to_date: `${year}-12-31`
        };
        handleAlert(2, 'Creating file...');
        setLoading(false);
        handleGlossaryClose();
        apiGetFile(`/articles/glossary/export`, data)
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                handleAlert(1, 'Glossary Created Successfully');
                link.setAttribute('download', `${year}-glossary-report.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(0, err);
            });

    }

    const toggleDrawer = () => {
        setOpenFilter(!openFilter)
    }

    const handleSingleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setSingleDate(date)
        setFromDate(null)
        setToDate(null)
        setShowClearDates(true)
    }

    const handleFromDateChange = (selectedDate) => {
        const startdate = moment(selectedDate)
        setFromDate(startdate)
        if (toDate === null) {
            var date = new Date(startdate)
            // eslint-disable-next-line
            Date.prototype.addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }
            setToDate(moment(date.addDays(1)))
        }
        setSingleDate(null)
        setShowClearDates(true)
    }

    const handleToDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setToDate(date)
        setSingleDate(null)
        setShowClearDates(true)
    }

    const handleArtSingleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setArtSingleDate(date)
        setArtFromDate(null)
        setArtToDate(null)
        setShowClearDates(true)
    }

    const handleArtFromDateChange = (selectedDate) => {
        const startdate = moment(selectedDate)
        setArtFromDate(startdate)
        if (toDate === null) {
            var date = new Date(startdate)
            // eslint-disable-next-line
            Date.prototype.addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }
            setArtToDate(moment(date.addDays(1)))
        }
        setArtSingleDate(null)
        setShowClearDates(true)
    }

    const handleArtToDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setArtToDate(date)
        setArtSingleDate(null)
        setShowClearDates(true)
    }


    const clearFilters = () => {
        toggleDrawer()
        setUser(user_)
        setFromDate(null)
        setToDate(null)
        setShowClear(false)
        setShowClearDates(false)
        setSingleDate(null)
        setArtFromDate(null)
        setArtToDate(null)
        setArtSingleDate(null)
        setStart(0)
        const data = {
            _start: 0,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            _fromDate: null,
            _toDate: null,
            _user: user_,
            _singleDate: null,
            _artSingleDate: null,
            _artFromDate: null,
            _artToDate: null,
        }
        getList(data)
    }

    const clearDates = () => {
        setFromDate(null)
        setToDate(null)
        setSingleDate(null)
        setArtFromDate(null)
        setArtToDate(null)
        setArtSingleDate(null)
        setShowClearDates(false)
    }

    const setExcelData = (groupedWords, id, name, zones) => {
        var data = []
        if (zones.length > 0) {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((zone) => {
                const zoneArray = Object.values(zone)
                zoneArray.forEach((parNum) => {

                    const parNumArray = Object.values(parNum)
                    parNumArray.forEach((para) => {

                        const paraArray = Object.values(para)
                        paraArray.forEach((word) => {

                            if (word.conf < conf) {
                                const object = {
                                    id: word.id,
                                    generated_text: word.generated_text,
                                    corrected_text: word.corrected_text,
                                }
                                data.push(object)
                            } else if (word.corrected_text) {
                                const object = {
                                    id: word.id,
                                    generated_text: word.generated_text,
                                    corrected_text: word.corrected_text,
                                }
                                data.push(object)
                            }

                        })

                    })

                });
            })
            handeExcel(data, id, name)
        } else {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((parNum) => {

                const parNumArray = Object.values(parNum)
                parNumArray.forEach((para) => {

                    const paraArray = Object.values(para)
                    paraArray.forEach((word) => {

                        if (word.conf < conf) {
                            const object = {
                                id: word.id,
                                generated_text: word.generated_text,
                                corrected_text: word.corrected_text,
                            }
                            data.push(object)
                        } else if (word.corrected_text) {
                            const object = {
                                id: word.id,
                                generated_text: word.generated_text,
                                corrected_text: word.corrected_text,
                            }
                            data.push(object)
                        }

                    })

                })

            });
            handeExcel(data, id, name)
        }
    };

    const handeExcel = (finalData, id, name) => {
        const emptyRows = [{
            id: '',
            generated_text: '',
            corrected_text: '',
        }, {
            id: '',
            generated_text: '',
            corrected_text: '',
        }, {
            id: '',
            generated_text: '',
            corrected_text: '',
        }]

        const fitToColumn = data => {
            const columnWidths = [];
            for (const property in data[4]) {
                columnWidths.push({ wch: Math.max(property ? property.toString().length : 0, ...data.map(obj => obj[property] ? obj[property].toString().length : 0)) });
            }
            return columnWidths;
        };
        const combinedData = emptyRows.concat(finalData)
        const ws = utils.json_to_sheet(combinedData)
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, [["Article Id", `${id}`, ""]], { origin: "A1" })
        utils.sheet_add_aoa(ws, [["Article Name", `${name}`, ""]], { origin: "A2" })
        utils.sheet_add_aoa(ws, [["Confidence Threshold", `${conf ? conf : 'NA'}`, ""]], { origin: "A3" })
        utils.sheet_add_aoa(ws, [["", "", ""]], { origin: "A4" })
        utils.sheet_add_aoa(ws, [["Word ID", "Generated Word", "Corrected Word"]], { origin: "A5" })
        ws['!cols'] = fitToColumn(combinedData)
        utils.book_append_sheet(wb, ws, 'Corrected Words')
        writeFile(wb, `${name}.xlsx`, { cellStyles: true })
        handleAlert(1, 'Glossary Downloaded!')
        setArticleId(null)
    }

    const handleImageZoning = (processedImage, zones) => {
        setLoading(true)
        const _zones = zones.length > 0 ? zones : null
        processedImage && setFile(processedImage)
        const image = processedImage ? processedImage : file
        var formData = new FormData()
        formData.append('article', image, `${fileName}`)
        formData.append('zones_list', JSON.stringify(_zones))
        const url = `/articles/${articleId}`
        apiPost(url, formData)
            .then(response => {
                setContent(response.data.words)
                setZones(response.data.zones)
                setArticleId(response.data.id)
                setOpenReOCR(false);
                setFile(null)
                setFileName(null)
                setZones([])
                const data = {
                    _start: start,
                    _limit: limit,
                    _sortBy: sortBy,
                    _order: order,
                    _search: search,
                    _fromDate: fromDate,
                    _toDate: toDate,
                    _user: user,
                    _singleDate: singleDate,
                    _artSingleDate: artSingleDate,
                    _artFromDate: artFromDate,
                    _artToDate: artToDate,
                }
                getList(data)
                setLoading(false)
            }).catch(err => {
                handleAlert(0, err)
                console.log(err);
                setLoading(false)
            });
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Articles List</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Button variant="contained" className='mx-2' color="primary" onClick={openGlossary}><DescriptionIcon fontSize='small' /> Glossary</Button>
                    <Button variant="contained" className='mx-2' color="primary" onClick={toggleDrawer}><FilterListIcon fontSize='small' /> Filters</Button>
                </Grid>
            </Grid>
            {/* Filter drawer */}
            <Drawer anchor='right' open={openFilter} onClose={toggleDrawer}>
                <div className={classes.filter}>
                    <Grid container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item >
                            <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                            >
                                <Grid item >
                                    <Tooltip title="Close"><IconButton onClick={toggleDrawer}><CloseIcon /></IconButton></Tooltip>
                                    &nbsp;
                                    <Button variant="contained" color="primary" onClick={() => {
                                        const data = {
                                            _start: start,
                                            _limit: limit,
                                            _sortBy: sortBy,
                                            _order: order,
                                            _search: search,
                                            _fromDate: fromDate,
                                            _toDate: toDate,
                                            _user: user,
                                            _singleDate: singleDate,
                                            _artSingleDate: artSingleDate,
                                            _artFromDate: artFromDate,
                                            _artToDate: artToDate,

                                        }
                                        getList(data)
                                        toggleDrawer()
                                    }}>
                                        Apply
                                    </Button>
                                    {showClear ? (
                                        <>
                                            &nbsp;
                                            &nbsp;
                                            <Button variant="contained" color="secondary" onClick={clearFilters}>
                                                Clear
                                            </Button>
                                        </>
                                    ) : ''}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={users}
                                value={user}
                                autoHighlight
                                getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
                                onChange={(e, value) => setUser(value)}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    className='mt-4'
                                    label="Upload Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={singleDate}
                                    onChange={handleSingleDateChange}
                                />
                                <DatePicker
                                    className='mt-4'
                                    label="Upload From Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                    maxDate={toDate ? toDate : '2100/01/01'}
                                />
                                <DatePicker
                                    className='mt-4'
                                    label="Upload To Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={toDate}
                                    onChange={handleToDateChange}
                                    minDate={fromDate ? fromDate : '1900/01/01'}
                                />

                                <DatePicker
                                    className='mt-4'
                                    label="Article Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={artSingleDate}
                                    onChange={handleArtSingleDateChange}
                                />
                                <DatePicker
                                    className='mt-4'
                                    label="Article From Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={artFromDate}
                                    onChange={handleArtFromDateChange}
                                    maxDate={artToDate ? artToDate : '2200/01/01'}
                                />
                                <DatePicker
                                    className='mt-4'
                                    label="Article To Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={artToDate}
                                    onChange={handleArtToDateChange}
                                    minDate={artFromDate ? artFromDate : '1000/01/01'}
                                />
                            </MuiPickersUtilsProvider>
                            {showClearDates ? (
                                <Button variant="contained" color="secondary" className='mt-4' onClick={clearDates}>
                                    Clear dates
                                </Button>
                            ) : ''}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
            <br />
            <Dialog
                open={dialogue1Toggle}
                onClose={handleDialogue1Close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {progress}
                <DialogTitle id="alert-dialog-title">Delete Article</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Article ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogue1Close} variant="outlined" autoFocus disabled={loading}>
                        Close
                    </Button>
                    <Button onClick={() => deleteItem(articleId)} variant="outlined" disabled={loading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={glossaryToggle}
                onClose={handleGlossaryClose}
                maxWidth='md'
            >
                {progress}
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Select Year
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            disabled={loading}
                            style={{ minWidth: '250px' }}
                            className='my-4'
                            inputVariant='outlined'
                            views={["year"]}
                            label="Glossary Year"
                            value={glossaryYear}
                            fullWidth
                            onChange={(value) => setGlossaryYear(value)}
                            minDate={'1800'}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGlossaryClose} variant="outlined" autoFocus disabled={loading}>
                        Cancel
                    </Button>
                    <Button onClick={() => downloadGlossary(glossaryYear)} variant="outlined" disabled={loading}>
                        Download
                    </Button>
                </DialogActions>
                {progress}
            </Dialog>
            <Dialog fullScreen open={openContent} onClose={handleCloseContent} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseContent} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Content
                        </Typography>
                        <Button autoFocus className='mx-2' variant="contained" disabled={loading} onClick={() => handleClickReOCR(articleId)}>
                            Edit Image
                        </Button>
                        <Button autoFocus className='mx-2' variant="contained" disabled={loading} onClick={() => getContent(articleId, false)}>
                            Copy
                        </Button>
                    </Toolbar>
                    {progress}
                </AppBar>
                {progress}
                {content && <EditableTextArea image={previewImage} data={content} conf={conf} articleId={articleId} zones={zones} setLoading={setLoading} handleAlert={handleAlert} />}
                {progress}
            </Dialog>
            <Dialog fullScreen open={openReOCR} onClose={handleCloseReOCR} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" disabled={loading} color="inherit" onClick={handleCloseReOCR} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Edit Image
                        </Typography>
                    </Toolbar>
                    {progress}
                </AppBar>
                {file && <ImageZoning files={file} zones={zones} handleImageZoning={handleImageZoning} loading={loading} progress={progress} />}
            </Dialog>
            {progress}
            <Table
                title="List Scans"
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
            {progress}
        </div>
    )
}

export default ListArticles