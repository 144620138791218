import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'

const UserDetails = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('aoeToken')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [user, setUser] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line
    }, [])

    const getProfile = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/user'
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setUser(user)
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                }
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const data = {
            first_name: firstName,
            last_name: lastName,
        }
        const url = URL + '/profile'
        axios.put(url, data, headerConfig).then(response => {
            user.first_name = firstName
            user.last_name = lastName
            localStorage.setItem('aoeUser', JSON.stringify(user))
            const message = 'Profile updated.'
            sendNotification(message)
            setDisabled(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setDisabled(false)
        })
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <form id="pro" name="pro" onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3} className="mt-5">
                                <TextField
                                    key="email"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    fullWidth
                                    disabled
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="fName"
                                    id="fName"
                                    name="fName"
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleFirstNameChange}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="lName"
                                    id="lName"
                                    name="lName"
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleLastNameChange}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UserDetails
