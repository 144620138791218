import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'

const AddUser = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('aoeToken')
    const [roles, setRoles] = useState([])
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [role, setRole] = useState(null)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const getRoles = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/roles/all'
        axios.get(url, headerConfig).then(response => {
            setRoles(response.data)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    useEffect(() => {
        getRoles()
        // eslint-disable-next-line
    }, [])

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const handlePasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPassword(value)
    }

    const handleRoleChange = (e, role) => {
        setRole(role.id)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            role: role
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/users'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'User added.'
            sendNotification(message)
            props.history.push('/dashboard/settings/users')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/settings/users">
                    Users
                </MLink>
                <Typography color="textPrimary">Add User</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                className='my-3'
                                label="First Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 50 }}
                                onChange={handleFirstNameChange}
                                fullWidth
                                required
                            />
                            <TextField
                                className='my-3'
                                label="Last Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 50 }}
                                onChange={handleLastNameChange}
                                fullWidth
                                required />
                            <TextField
                                className='my-3'
                                label="Email"
                                variant="outlined"
                                inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                                onChange={handleEmailChange}
                                fullWidth
                                required />
                            <TextField
                                className='my-3'
                                label="Password"
                                variant="outlined"
                                type="password"
                                inputProps={{ minLength: 6, maxLength: 50 }}
                                onChange={handlePasswordChange}
                                fullWidth
                                required />
                            <Autocomplete
                                className='my-3'
                                options={roles}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Role"
                                    variant="outlined"
                                    required
                                />}
                                onChange={handleRoleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/settings/users"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit">
                                Add
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddUser
