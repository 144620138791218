import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Link as MLink,
    TextField,
    Typography,
    Snackbar,
    LinearProgress,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { apiGet, apiPost } from '../../../functionsAPI'
import { Alert } from '@material-ui/lab'

const AddRole    = (props) => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleAlert = (type, message) => {
        switch (type) {
            case 0:
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 1:
                setAlertType('success')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 2:
                setAlertType('info')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 3:
                setAlertType('warning')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            default:
                setAlertType('warning')
                setAlertMessage('Unknown Message')
                setShowAlert(true)
                break;
        }
    }
    const [name, setName] = useState(null)
    const [operations, setOperations] = useState([])
    const [state, setState] = useState({})

    const getOperations = () => {
        setLoading(true)
        const url = '/operations/all'
        apiGet(url)
            .then(response => {
                setOperations(response.data)
                setLoading(false)
            })
            .catch(error => {
                handleAlert(0, error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({ ...state, [name]: value })
    }

    const nameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        var operations = []
        for (var key in state) {
            if (state[key] === true) {
                operations.push(key)
            }
        }
        if (operations.length === 0) {
            const message = 'Select at least one operation.'
            handleAlert(0, message)
        }
        else {
            var _name = name
            _name = ltrim(_name)
            _name = rtrim(_name)
            const data = {
                name: _name,
                operations
            }
            const url = '/roles'
            apiPost(url, data)
                .then(response => {
                    const message = 'Role added.'
                    handleAlert(1, message)
                    props.history.push('/dashboard/settings/roles')
                })
                .catch(error => {
                    handleAlert(0, error)
                })
        }
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/settings/roles">
                    Roles
                </MLink>
                <Typography color="textPrimary">Add Role</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit} className="mt-5">
                        {progress}
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Role Name"
                                    name="name"
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 50 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {operations.map((operation, index) => {
                                    return (
                                        <Accordion id={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={operation.id}
                                                id={operation.id}
                                            >
                                                <Typography>{operation.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid item xs={12}>
                                                    <FormGroup row>
                                                        {
                                                            operation.operations.map((child, index) => {
                                                                return (
                                                                    <Grid key={index} item xs={2}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <div>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={child.id}
                                                                                        checked={state[child.id]}
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    &nbsp;
                                                                                </div>
                                                                            }
                                                                            label={child.name}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/roles">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        {progress}
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddRole
