import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    Link as MLink,
    Typography,
    Snackbar,
    makeStyles,
    LinearProgress,
} from '@material-ui/core'
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import { Link } from 'react-router-dom'
import BarChartIcon from '@material-ui/icons/BarChart';
import { apiGet, apiPut } from '../../functionsAPI'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles({
    root: {
        width: 250,
    },
    input: {
        width: 42,
    },
});

const Configurations = (props) => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleAlert = (type, message) => {
        switch (type) {
            case 0:
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 1:
                setAlertType('success')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 2:
                setAlertType('info')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            case 3:
                setAlertType('warning')
                setAlertMessage(message)
                setShowAlert(true)
                break;
            default:
                setAlertType('warning')
                setAlertMessage('Unknown Message')
                setShowAlert(true)
                break;
        }
    }
    const classes = useStyles();
    const [value, setValue] = useState(30);
    const [configId, setConfigId] = useState(null)

    const getConfig = () => {
        setLoading(true)
        apiGet(`/settings/all`)
            .then((res) => {
                console.log(res);
                setValue(Number(res.data[0].conf))
                setConfigId(res.data[0].id)
                setLoading(false)
            })
            .catch((err) => {
                handleAlert(0, 'error')
                console.log(err);
                setLoading(false)
            });
    }



    useEffect(() => {
        getConfig()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            conf: value ? value : 0,
        }
        apiPut(`/settings/${configId}`, data)
            .then((res) => {
                handleAlert(1, 'Confidence Threshold Updated.')
            })
            .catch((err) => {
                handleAlert(0, 'Update Failed')
                console.log(err);
                setLoading(false)
            });
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Configurations</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit} className="mt-5">
                        {progress}
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={6}>
                                <Typography id="input-slider" gutterBottom>
                                    Confidence Threshold
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <BarChartIcon />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            value={typeof value === 'number' ? value : 0}
                                            onChange={handleSliderChange}
                                            aria-labelledby="input-slider"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            className={classes.input}
                                            value={value}
                                            margin="dense"
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 100,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <Link to="/dashboard/articles" className='m-3'>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Scan List
                                    </Button>
                                </Link>
                                <Button
                                    className='m-3'
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                        {progress}
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default Configurations
