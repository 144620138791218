import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Grid,
    LinearProgress,
    Typography
} from '@material-ui/core'
import TotalWidgetCard from '../components/TotalWidgetCard'
import ListRecentScanResults from './scans/ListRecentScanResults'
import { apiGet } from '../functionsAPI'

const Dashboard = () => {
    const _user = JSON.parse(localStorage.getItem('aoeUser'))
    const [widgets, setWidgets] = useState({
        users: 0,
        scans: 0
    })
    const [loading, setLoading] = useState(false)
    const [showUsers, setShowUsers] = useState(false)

    const getData = () => {
        setLoading(true)
        const url = '/dashboard'
        apiGet(url)
            .then(response => {
                setWidgets(response.data)
                setLoading(false)
            })
            .catch(error => {
                if (error.response) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                setLoading(false)
            })
    }


    const getRole = () => {
        setLoading(true)
        const url = '/user'
        apiGet(url)
            .then(res => {
                if (res.data.role && res.data.role.name === 'Super Admin') {
                    setShowUsers(true)
                }
                setLoading(false)
            })
            .catch(error => {
                if (error.response) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                setLoading(false)
            })
    }

    const getConfig = () => {
        apiGet(`/settings/all`)
            .then((res) => {
                const value = Number(res.data[0].conf)
                localStorage.setItem('confThreshold', value)
            })
            .catch((err) => {
                // handleAlert(0, 'error while fetching confidence threshold')
                console.log(err);
            });
    }

    useEffect(() => {
        getData()
        getRole()
        getConfig()
        // eslint-disable-next-line
    }, [])

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Dashboard</Typography>
            </Breadcrumbs>
            <br />
            {progress}
            <Grid item xs={12}>
                <Grid container justify="center" spacing="3">
                    <Grid item>
                        <TotalWidgetCard title="Today's Scans" body={widgets.scans} />
                    </Grid>
                    {showUsers && <Grid item>
                        <TotalWidgetCard title="Total Users" body={widgets.users} />
                    </Grid>}
                </Grid>
            </Grid>
            <ListRecentScanResults />
        </div>
    )
}

export default Dashboard
