import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'

const UpdateUser = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('aoeToken')
    const userId = props.match.params.userId
    const [roles, setRoles] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState(null)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    const getRoles = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/roles/all'
        axios.get(url, headerConfig).then(response => {
            setRoles(response.data)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    const getUser = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/users/' + userId
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setRole(user.role)
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    useEffect(() => {
        getRoles()
        getUser()
        // eslint-disable-next-line
    }, [])

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const handleRoleChange = (e, role) => {
        setRole(role)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            first_name: firstName,
            last_name: lastName,
            role_id: role.id
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/users/' + userId
        axios.put(url, data, headerConfig).then(response => {
            const message = 'User updated.'
            sendNotification(message)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/settings/users">
                    Users
                </MLink>
                <Typography color="textPrimary">Update User</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                disabled
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="fName"
                                name="fName"
                                label="First Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 50 }}
                                value={firstName}
                                onChange={handleFirstNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="lName"
                                name="lName"
                                label="Last Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 50 }}
                                value={lastName}
                                onChange={handleLastNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <Autocomplete
                                options={roles}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={role}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Role"
                                    variant="outlined"
                                    required
                                />}
                                onChange={handleRoleChange}
                            />
                            <br /><br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/settings/users"><Button variant="contained">Back</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit">
                                Update
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateUser
