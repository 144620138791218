import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { Tooltip } from '@material-ui/core';
import { apiGet } from '../functionsAPI';

const EditableWord = ({ word, onWordEdit, conf, darkState, highlightWord, previousWordMerge, postWordMerge, articleWords, parentRef }) => {
    const [isEditing, setEditing] = useState(false);
    const [editedWord, setEditedWord] = useState(word.corrected_text ?? word.generated_text);
    const originalWord = useRef(word.corrected_text ?? word.generated_text)
    const edited = word.corrected_text !== null
    const [suggestions, setSuggestions] = useState([])
    const [showSuggestion, setShowSuggestions] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null);

    const inputRef = useRef(null);

    // setting input field width based on the length of word
    let fieldLength = editedWord.length * 10;
    if (fieldLength < 45) {
        fieldLength = 45;
    }

    // conditionally set tabindex
    // once the suspected word is corrected, it should not come while navigating through tabs
    // let tabIndex = word.conf < conf ? word.corrected_text ? '-1' : '0' : '-1';
    let tabIndex = word.conf <= conf ? '0' : '-1';

    // conditionally setting bootstrap classnames for text color and decoration
    let className = 'text-reset text-decoration-none p-0 my-0 me-1';

    if (darkState === 'light') {
        if (edited) {
            className = 'text-success text-decoration-none p-0 my-0 me-1';
        } else if (word.conf < conf) {
            className = 'text-warning p-0 my-0 me-1';
        }
    } else {
        if (edited) {
            className = 'text-success text-decoration-none p-0 my-0 me-1';
        } else if (word.conf < conf) {
            className = 'text-primary p-0 my-0 me-1';
        }
    }

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const handleNumbersPress = (e) => {
        if (showSuggestion && suggestions.length > 0) {
            // Iterate over pressed keys and find the corresponding suggestion
            const number = e.key
            if (number > 0 && number <= suggestions.length) {
                // Extract the suggested word corresponding to the number
                const suggestedWord = suggestions[number - 1]
                // Set the suggested word without appending the number
                e.preventDefault()
                handleSuggestion(suggestedWord)
            }
        }
    }

    const handleEdit = () => {
        if (originalWord.current !== editedWord) {
            onWordEdit(word.id, editedWord);
            originalWord.current = editedWord
        }
        setEditing(false);
        highlightWord(null);
        setSuggestions([])
        setShowSuggestions(false)
    };

    const handleSuggestion = (suggestedWord) => {
        if (originalWord.current !== suggestedWord) {
            onWordEdit(word.id, suggestedWord)
            setEditedWord(suggestedWord)
            originalWord.current = editedWord
        }
        setEditing(true)
        highlightWord(word)
    };

    const handleClick = () => {
        getWords()
        setShowSuggestions(true)
        setEditing(true);
    };

    useEffect(() => {
        if (isEditing) {
            getWords()
            setShowSuggestions(true)
        }
        return () => { setShowSuggestions(false) }
        // eslint-disable-next-line
    }, [editedWord])

    const handleKeyDown1 = (e) => {
        if (e.key === 'Enter') {
            handleClick();
        } else {
            const number = e.key
            !isNaN(number) && handleNumbersPress(e)
        }
    }

    const handleKeyDown2 = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleEdit();
        } else if (e.key === 'Tab') {
            handleEdit();
        } else {
            const number = e.key
            !isNaN(number) && handleNumbersPress(e)
        }
    };

    const handlePreviousWordMerge = () => {
        const index = articleWords.findIndex((unit) => unit === word)
        const prevWord = articleWords[index - 1]
        setEditedWord((prevWord.corrected_text ?? prevWord.generated_text) + ' ' + editedWord)
        previousWordMerge(word)
    }

    const handlePostWordMerge = () => {
        const index = articleWords.findIndex((unit) => unit === word)
        const postWord = articleWords[index + 1]
        setEditedWord(editedWord + ' ' + (postWord.corrected_text ?? postWord.generated_text))
        postWordMerge(word)
    }

    const getWords = () => {
        if (editedWord.length > 2) {
            const data = {
                word: editedWord
            }
            apiGet(`/articles/suggest-words`, data)
                .then((res) => {
                    setSuggestions(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleStateChange = (value) => {
        const callFirstFunctions = () => {
            highlightWord(word)
            setShowSuggestions(true)
            setTimeoutId(setTimeout(function () {
                getWords()
            }, 200)); // 1000 milliseconds = 1 second
        }

        const callSecondFunctions = () => {
            clearTimeout(timeoutId)
            highlightWord(null)
            setSuggestions([])
            setShowSuggestions(false)
        };

        if (value) {
            callFirstFunctions()
        } else {
            callSecondFunctions()
        }
    }

    return (
        <span>
            {isEditing ? (
                <>
                    <Tooltip title="Merge with Previous word" ><MergeTypeIcon cursor='pointer' onClick={() => handlePreviousWordMerge(word)} /></Tooltip>
                    <input
                        ref={inputRef}
                        type="text"
                        value={editedWord}
                        onChange={(e) => setEditedWord(e.target.value)}
                        onBlur={() => {
                            handleEdit()
                            setSuggestions([])
                            setShowSuggestions(false)
                        }}
                        onKeyDown={handleKeyDown2}
                        spellCheck={false}
                        onFocus={() => {
                            highlightWord(word)
                            setShowSuggestions(true)
                        }}
                        style={{
                            color: 'inherit',
                            background: 'inherit',
                            padding: '5px',
                            borderRadius: '3px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                            textDecoration: 'none',
                            outline: 'none',
                            minWidth: `${fieldLength / 3}px`,
                            maxidth: `${fieldLength}px`,
                        }}
                    />
                    <Tooltip title="Merge with Following word"><MergeTypeIcon cursor='pointer' onClick={() => handlePostWordMerge(word)} /></Tooltip>
                </>
            ) : (
                <Button
                    ref={inputRef}
                    variant={'link'}
                    className={className}
                    spellCheck={false}
                    onKeyDown={handleKeyDown1}
                    onClick={handleClick}
                    onFocus={() => handleStateChange(true)}
                    onBlur={() => handleStateChange(false)}
                    tabIndex={tabIndex}
                    style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        width: 'auto',
                        maxWidth: `${fieldLength}px`,
                    }}
                >
                    {editedWord}
                </Button>
            )}

            {showSuggestion && parentRef && suggestions.length > 0 &&
                <div className='rounded py-2' style={{ position: 'fixed', top: inputRef.current ? (inputRef.current.offsetTop - parentRef.scrollTop) + 32 : 100, left: inputRef.current ? inputRef.current.offsetLeft : 100, zIndex: '2', background: '#fff', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.4) 0px 5px 15px' }}>

                    {suggestions.map((item, index) => {
                        return <div key={item} className='move suggested-word px-3 py-1 pointer text-dark' style={{ cursor: 'pointer' }} onClick={() => setEditedWord(suggestions[index])}>{index + 1}. {item}
                        </div>

                    })}

                </div>}
        </span>
    );
};

export default EditableWord;