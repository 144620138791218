import moment from 'moment'

export function dateToString(date) {
    return date.format('yyyy-MM-DD')
}

export function stringToDate(date) {
    return moment(date, 'yyyy-MM-DD')
}

export function stringToDateTime(date) {
    return moment(date, 'yyyy-MM-DD hh:mm:ss')
}

export function dateFormate(date) {
    const mDate = stringToDate(date)
    return mDate.format('DD/MM/yyyy')
}

export function dateTimeFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('DD/MM/yyyy hh:mm A')
}

export function yearFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('yyyy')
}

export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function checkOperations(operation) {
    const operations = JSON.parse(localStorage.getItem('aoeOperations'))
    const status = operations.includes(operation)
    return status
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}