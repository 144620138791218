import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { apiDelete, apiPut } from '../functionsAPI'
import EditableWord from './EditableWord'
import ImageHighlight from './ImageHighlight'

const EditableTextArea = (props) => {
	const highlightColRef = useRef(null)
	const editColRef = useRef(null)
	const scaleRef = useRef(1)
	const darkState = localStorage.getItem('darkState')
	const jsonArray = Object.values(props.data)
	const [paragraphWords, setParagraphWords] = useState(jsonArray)
	const [highlightedWord, setHighlightedWord] = useState(null)

	function sortObjectsOrder(paragraphWords) {
		if (!Array.isArray(paragraphWords)) {
			return []
		}

		return paragraphWords.sort((a, b) => {
			if (a.zone && b.zone) {
				if (a.zone.ocr_sequence !== b.zone.ocr_sequence) {
					return a.zone.ocr_sequence - b.zone.ocr_sequence
				}
			}

			if (a.block_num !== b.block_num) {
				return a.block_num - b.block_num
			}

			if (a.par_num !== b.par_num) {
				return a.par_num - b.par_num
			}

			if (a.line_num !== b.line_num) {
				return a.line_num - b.line_num
			}

			return a.word_num - b.word_num
		})
	}

	function groupByBlocks(sortedWords) {
		return sortedWords.reduce((groups, word) => {
			const { zone, block_num, par_num } = word
			if (!zone) {
				if (!groups[block_num]) {
					groups[block_num] = {}
				}

				if (!groups[block_num][par_num]) {
					groups[block_num][par_num] = []
				}

				groups[block_num][par_num].push(word)

			} else {
				if (!groups[zone.ocr_sequence]) {
					groups[zone.ocr_sequence] = {}
				}

				if (!groups[zone.ocr_sequence][block_num]) {
					groups[zone.ocr_sequence][block_num] = []
				}

				if (!groups[zone.ocr_sequence][block_num][par_num]) {
					groups[zone.ocr_sequence][block_num][par_num] = []
				}

				groups[zone.ocr_sequence][block_num][par_num].push(word)
			}
			return groups
		}, {})
	}

	const sortedWords = sortObjectsOrder(paragraphWords)
	const groupWords = groupByBlocks(sortedWords)
	const groupedWords = Object.values(groupWords)

	const handleWordEdit = async (wordId, editedText) => {
		try {
			props.setLoading(true)

			const data = {
				corrected_text: editedText,
			}

			const res = await apiPut(`/articles/${props.articleId}/words/${wordId}`, data)

			setParagraphWords((prevWords) =>
				prevWords.map((word) => (word.id === wordId ? res.data : word))
			)

		} catch (err) {
			console.error(err)
			props.handleAlert(0, 'Update Failed')

		} finally {
			props.setLoading(false)
		}
	}

	const handleWordDelete = (wordId) => {
		props.setLoading(true)
		apiDelete(`/articles/${props.articleId}/words/${wordId}`)
			.then((res) => {
				props.setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				props.handleAlert(0, err)
				props.setLoading(false)
			})
		props.setLoading(false)
	}

	const handleMouseDown = (event) => {
		// Prevent default action for both left and right clicks
		event.preventDefault()
	}

	const highlightWord = (word) => {
		setHighlightedWord(word)
		handleScroll(word)
	}

	const handleScroll = (word) => {
		// logic: Determining the word's position relative to the original image
		// Subsequently, obtaining the scrollable height of the rendered image element in the DOM
		// Finally, directing the scrollable DOM element to the determined position

		const scale = scaleRef.current
		const parent = highlightColRef.current
		const scrollableHeight = parent.scrollHeight
		const scrollableWidth = parent.scrollWidth
		const viewPortHeight = parent.offsetHeight
		const viewPortWidth = parent.offsetWidth
		if (parent.children[1]) {
			const imageHeight = parent.children[1].height
			const imageWidth = parent.children[1].width

			if (word) {
				let wordPositionX
				let wordPositionY
				if (word.zone) {
					wordPositionX = parseInt(word.zone.left) + word.left
					wordPositionY = parseInt(word.zone.top) + word.top
				} else {
					wordPositionX = word.left
					wordPositionY = word.top
				}
				const relativeWordPositionX = wordPositionX / imageWidth
				const relativeWordPositionY = wordPositionY / imageHeight

				parent.scrollTop = ((scrollableHeight * relativeWordPositionY) - (viewPortHeight / 2)) * scale
				parent.scrollLeft = ((scrollableWidth * relativeWordPositionX) - (viewPortWidth / 2)) * scale
			}
		}
	}

	const previousWordMerge = (word) => {
		props.setLoading(true)
		const index = paragraphWords.findIndex((unit) => unit === word)
		const prevWord = paragraphWords[index - 1]
		setParagraphWords((prevWords) =>
			prevWords.filter((singleWord) => singleWord.id !== prevWord.id)
		)
		handleWordDelete(prevWord.id)
		props.setLoading(false)
	}

	const postWordMerge = (word) => {
		props.setLoading(true)
		const index = paragraphWords.findIndex((unit) => unit === word)
		const postWord = paragraphWords[index + 1]
		setParagraphWords((prevWords) =>
			prevWords.filter((singleWord) => singleWord.id !== postWord.id)
		)
		handleWordDelete(postWord.id)
		props.setLoading(false)
	}

	return (
		<section>
			<Container fluid>
				<Row>
					<Col ref={highlightColRef} className='border rounded py-3 my-3 d-flex align-items-start scrolable-editor' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
						{props.image &&
							<ImageHighlight scaleRef={(scale) => scaleRef.current = scale} parentRef={highlightColRef.current} imgSrc={props.image} word={highlightedWord} zones={props.zones} />
						}
						{!props.image &&
							<div style={{ display: !props.image ? "block" : "none" }} class="spinner-border text-primary align-self-center m-auto" />
						}
					</Col>

					<Col
						ref={editColRef}
						className="border rounded p-3 my-3 mx-1 d-flex flex-column scrolable-editor"
						onMouseDown={handleMouseDown}
						onContextMenu={handleMouseDown}
						style={{ maxHeight: '80vh', overflowY: 'scroll' }}
					> {props.zones.length > 0 && groupedWords.map((zone) => {
						const zoneArray = Object.values(zone)
						return <>
							{zoneArray.map((block_num) => {
								return <>
									{block_num.map((block) => {
										const paragraphs = Object.values(block)
										return <p>
											{paragraphs.map((word) => {
												return <EditableWord word={word} onWordEdit={handleWordEdit} key={word.id} conf={props.conf} darkState={darkState} highlightWord={highlightWord} previousWordMerge={previousWordMerge} postWordMerge={postWordMerge} articleWords={paragraphWords} parentRef={editColRef.current} />
											})}
										</p>
									})}
								</>
							})}
						</>
					})}
						{!props.zones.length > 0 && groupedWords.map((block_num) => {
							const blocks = Object.values(block_num)
							return <>

								{blocks.map((block) => {
									const paragraphs = Object.values(block)
									return <p>
										{paragraphs.map((word) => {
											return <EditableWord word={word} onWordEdit={handleWordEdit} key={word.id} conf={props.conf} darkState={darkState} highlightWord={highlightWord} previousWordMerge={previousWordMerge} postWordMerge={postWordMerge} articleWords={paragraphWords} parentRef={editColRef.current} />
										})}
									</p>
								})}
							</>
						})}
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default EditableTextArea