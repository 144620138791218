import React, { useState, useEffect } from 'react'
import {
    AppBar,
    Button,
    Dialog,
    IconButton,
    LinearProgress,
    Slide,
    Snackbar,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { apiGet, apiGetFile } from '../../functionsAPI'
import { checkOperations, dateFormate } from '../../functions'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import EditableTextArea from '../../components/EditableTextArea'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ImageIcon from '@material-ui/icons/Image'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import Table from '../../components/Table'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const ListRecentScanResults = (props) => {
    const conf = localStorage.getItem('confThreshold')
    const user_ = JSON.parse(localStorage.getItem('aoeUser'))
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setShowAlert(false)
    }

    const handleAlert = (type, message) => {
        switch (type) {
            case 0:
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                break
            case 1:
                setAlertType('success')
                setAlertMessage(message)
                setShowAlert(true)
                break
            case 2:
                setAlertType('info')
                setAlertMessage(message)
                setShowAlert(true)
                break
            case 3:
                setAlertType('warning')
                setAlertMessage(message)
                setShowAlert(true)
                break
            default:
                setAlertType('warning')
                setAlertMessage('Unknown Message')
                setShowAlert(true)
                break
        }
    }
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [openContent, setOpenContent] = useState(false)
    const [content, setContent] = useState(null)
    const [zones, setZones] = useState([])
    const [previewImage, setPreviewImage] = useState(null)
    const [articleId, setArticleId] = useState(null)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options)
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Article Name",
            options: {
                sort: true,
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            user_id: user_ ? user_.id : 'all'
        }
        const url = '/articles'
        apiGet(url, params).then(response => {
            response.data.list.map(item => {
                var action = ''
                action = (
                    <div>
                        {checkOperations('View Article') && <Tooltip title="Edit Article">
                            <IconButton onClick={() => {
                                getContent(item.id, true)
                            }}>
                                <SpellcheckIcon />
                            </IconButton>
                        </Tooltip>}
                        {checkOperations('View Article') && <Tooltip title="Copy Article">
                            <IconButton onClick={() => {
                                getContent(item.id, false)
                            }}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>}
                        {checkOperations('View Article') &&
                            <Tooltip title="Download Image">
                                <IconButton >
                                    <ImageIcon onClick={() => downloadImage(item.path, item.name)} />
                                </IconButton>
                            </Tooltip>}
                        {/* <Tooltip title="Delete Result">
                                <IconButton onClick={() => handleClickOpen(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip> */}
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const getContent = (id, value) => {
        setLoading(true)
        setArticleId(id)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setContent(res.data.words)
                getImage(res.data.path)
                setZones(res.data.zones)
                const objectsData = Object.values(res.data.words)
                const sortedWords = sortObjectsOrder(objectsData)
                const groupedWords = groupByBlocks(sortedWords)
                value && handleClickOpenContent()
                !value && setData(groupedWords, res.data.zones)
                setLoading(false)
            })
            .catch((err) => {
                // handleAlert(0, 'error')
                console.log(err)
                setLoading(false)
            })
    }

    const getImage = (path) => {
        apiGetFile('/files?f=' + path)
            .then((res) => {
                const url = new Blob([res.data], { type: res.data.type })
                setPreviewImage(url)
            })
            .catch((err) => {
                console.log(err)
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            })
    }

    function sortObjectsOrder(paragraphWords) {
        if (!Array.isArray(paragraphWords)) {
            return []
        }

        return paragraphWords.sort((a, b) => {
            if (a.zone && b.zone) {
                if (a.zone.ocr_sequence !== b.zone.ocr_sequence) {
                    return a.zone.ocr_sequence - b.zone.ocr_sequence
                }
            }

            if (a.block_num !== b.block_num) {
                return a.block_num - b.block_num
            }

            if (a.par_num !== b.par_num) {
                return a.par_num - b.par_num
            }

            if (a.line_num !== b.line_num) {
                return a.line_num - b.line_num
            }

            return a.word_num - b.word_num
        })
    }


    function groupByBlocks(sortedWords) {
        return sortedWords.reduce((groups, word) => {
            const { zone, block_num, par_num } = word
            if (!zone) {
                if (!groups[block_num]) {
                    groups[block_num] = {}
                }

                if (!groups[block_num][par_num]) {
                    groups[block_num][par_num] = []
                }

                groups[block_num][par_num].push(word)

            } else {
                if (!groups[zone.ocr_sequence]) {
                    groups[zone.ocr_sequence] = {}
                }

                if (!groups[zone.ocr_sequence][block_num]) {
                    groups[zone.ocr_sequence][block_num] = []
                }

                if (!groups[zone.ocr_sequence][block_num][par_num]) {
                    groups[zone.ocr_sequence][block_num][par_num] = []
                }

                groups[zone.ocr_sequence][block_num][par_num].push(word)
            }
            return groups
        }, {})
    }

    const setData = (groupedWords, zones) => {
        var data = ''
        if (zones.length > 0) {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((zone) => {
                const zoneArray = Object.values(zone)

                zoneArray.forEach((parNum) => {

                    const parNumArray = Object.values(parNum)
                    parNumArray.forEach((para) => {

                        const paraArray = Object.values(para)
                        // rendering of paragraph
                        paraArray.forEach((word) => {

                            data += word.corrected_text ?? word.generated_text
                            data += ' '

                        })
                        data += '\n\n'

                    })

                })

            })
            copyToClipboard(data)
        } else {
            const groupedWordsArray = Object.values(groupedWords)
            groupedWordsArray.forEach((parNum) => {

                const parNumArray = Object.values(parNum)
                parNumArray.forEach((para) => {

                    const paraArray = Object.values(para)
                    // rendering of paragraph
                    paraArray.forEach((word) => {

                        data += word.corrected_text ?? word.generated_text
                        data += ' '

                    })
                    data += '\n\n'

                })

            })
            copyToClipboard(data)
        }
    }

    const downloadImage = (path, name) => {
        apiGetFile('/files?f=' + path)
            .then((res) => {
                const image_type = res.data.type.split("/")[1]
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.download = `${name}.${image_type}`
                document.body.appendChild(link)
                link.click()
                link.remove()
            })
            .catch((err) => {
                console.log(err)
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            })
    }

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    // const deleteItem = (itemId) => {
    //     setLoading(true)
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             token
    //         }
    //     }
    //     const url = URL + '/batch/' + batchId  + '/' + itemId
    //     axios.delete(url, headerConfig).then(response => {
    //         const message = 'Result deleted.'
    //         sendNotification(message)
    //         setOpen(false)
    //         setLoading(false)
    //         getList()
    //     }).catch(error => {
    //         let message = 'Try after some time.'
    //         if (error.response) {
    //             if (error.response.status === 401) {
    //                 message = error.response.data.detail
    //             }
    //             (process.env.NODE_ENV !== 'production') && console.log(error.response)
    //         } else if (error.request) {
    //             (process.env.NODE_ENV !== 'production') && console.log(error.request)
    //         } else {
    //             (process.env.NODE_ENV !== 'production') && console.log(error)
    //         }
    //         sendNotification(message, 'error')
    //         setLoading(false)
    //     })
    // }

    // const handleClickOpen = (itemId) => {
    //     setOpen(true)
    //     setItemId(itemId)
    // }

    const handleClickOpenContent = () => {
        setLoading(true)
        setOpenContent(true)
    }

    const handleClose = () => {
        setOpenContent(false)
        setContent(null)
        setPreviewImage(null)
        setArticleId(null)
        // setOpen(false)
    }

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data)
        const message = 'Copied to clipboard.'
        handleAlert(1, message)
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <br />
            <Dialog fullScreen open={openContent} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Content
                        </Typography>
                        <Button autoFocus variant="contained" onClick={() => getContent(articleId, false)}>
                            Copy
                        </Button>
                    </Toolbar>
                </AppBar>
                {content && <EditableTextArea image={previewImage} data={content} articleId={articleId} conf={conf} zones={zones} setLoading={setLoading} handleAlert={handleAlert} />}

            </Dialog>
            {/* <Dialog
            open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {progress}
                <DialogTitle id="alert-dialog-title">Delete Result</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Result ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus disabled={loading}>
                        Close
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} variant="outlined" disabled={loading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog> */}
            {progress}
            <Table
                title="List Recent Scans Results"
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListRecentScanResults
